import { ActionTree } from 'vuex'
import { PartnerService } from '@/services/PartnerService'

import types from './types'
const { GENERIC_MUTATION } = types

const setAccountInfo = ({ commit }: any, value: any) =>
  commit(GENERIC_MUTATION, {
    property: 'accountInfo',
    value
  })

const setUserInfo = ({ commit }: any, value: any) =>
  commit(GENERIC_MUTATION, {
    property: 'userInfo',
    value
  })

const setCurrentInConfiguration = ({ commit }: any, value: any) =>
  commit(GENERIC_MUTATION, {
    property: 'currentInConfiguration',
    value
  })

const setPartnerCustomers = ({ commit }: any, customers: any) => {
    commit(GENERIC_MUTATION, {
      property: 'partnerCustomers',
      value: customers
    })
  }

const fetchPartnerCustomers = async (
    { dispatch }: any,
    { partnerHash, pageNumber, pageSize, order, filter }: any
    ) => {
      try {
        const customers = (await PartnerService.getInstance().getPartnerCustomers(partnerHash, pageNumber, pageSize, order, filter)) as any
        dispatch('setPartnerCustomers', customers)
      } catch (err) { }
  }

export default <ActionTree<any, any>>{
  setAccountInfo,
  setUserInfo,
  setCurrentInConfiguration,
  setPartnerCustomers,
  fetchPartnerCustomers
}
