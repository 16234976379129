import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/views/login/index.vue'
import RecoverPassword from '@/views/login/recover/index.vue'
import CreateAccount from '@/views/login/register/index.vue'
import ActiveAccount from '@/views/login/active/index.vue'
import ValidateUser from '@/views/login/validateUser/index.vue'
import Authenticator from '@/views/login/authenticator/index.vue'
import Document from '@/views/document/index.vue'
import NovoDocumento from '@/views/document/upload/index.vue'
import TelaPrincpal from '@/views/home/index.vue'
import ActiveUserAccount from '@/views/login/activeUser/index.vue'
import ConfiguracaoConta from '@/views/configuracao/index.vue'
import CreateAccountMvo from '@/views/login/mvo/index.vue'
import CreateAccount2 from '@/views/login/testeab/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login,
    children: [
      {
        name: 'authenticator',
        path: '/login',
        component: Authenticator
      },
      {
        name: 'cadastro',
        path: '/login/cadastro',
        component: CreateAccountMvo
      },
      {
        name: 'cadastro-externo',
        path: '/login/cadastro-externo',
        component: CreateAccount
      },
      {
        path: '/login/validate/user',
        component: ValidateUser
      },
      {
        name: 'cadastro-novo',
        path: '/login/cadastro-novo',
        component: CreateAccountMvo
      },
      {
        name: 'cadastro-mvo',
        path: '/login/cadastro/:campaign',
        component: CreateAccountMvo
      },
      {
        name: 'active-account',
        path: '/login/active/account',
        component: ActiveAccount
      }
    ]
  },
  {
    name: 'recuperacao',
    component: RecoverPassword,
    path: '/recover-password',
    props: true
  },
  {
    name: 'configuracao',
    component: ConfiguracaoConta,
    path: '/configuracao-conta',
    props: true
  },
  {
    path: '/document',
    component: Document,
    children: [
      {
        name: 'novoDocumento',
        path: '/document/new',
        component: NovoDocumento
      }
    ]
  },
  {
    name: 'home',
    path: '/home',
    component: TelaPrincpal
  },
  {
    name: 'active',
    path: '/active/:id/:code',
    component: ActiveUserAccount
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
