import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { notification } from 'ant-design-vue'
import { ErrorResponse } from './responses/ErrorResponse'
import router from '@/router'

export abstract class HTTPBaseService {
  protected instance: AxiosInstance;
  protected readonly baseURL: string;

  public constructor (baseURL: string) {
    this.baseURL = baseURL
    this.instance = axios.create({
      baseURL
    })
    this.initializeRequestInterceptor()
    this.initializeResponseInterceptor()
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest)
  };

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use((response) => {
      return response
    }, this.handleError)
  };

  private handleRequest = (config: AxiosRequestConfig | any) => {
    if (localStorage.getItem('token')) { config!.headers.Authorization = `Bearer ${atob(localStorage.getItem('token') || '')}` }
    return config
  };

  private handleError = async (error: AxiosError<ErrorResponse>) => {
    if (error.response?.status === 401) {
      this.presentAlert(
        {
          message: 'Falha',
          description:
            'Você precisa estar logado para executar a ação!'
        })
      localStorage.clear()
      router.push('/login')
      throw new Error(error.response?.statusText)
    } else if (error.response?.status === 400) {
      for (const iterator of error.response.data.notifications) {
        this.presentAlert({
          message: error.response!.data.title,
          description: iterator.message
        })
      }
      throw new Error(error.response?.statusText)
    } else if (error.response?.status === 402) {
      for (const iterator of error.response.data.notifications) {
        this.presentAlert({
          message: error.response!.data.title,
          description: iterator.message
        })
      }
      throw new Error(error.response?.statusText)
    } else if (error.response?.status === 500) {
      this.presentAlert({
        message: 'Falha',
        description: 'Erro interno do servidor'
      })
      throw new Error(error.response?.statusText)
    }
  };

  presentAlert (option: any) {
    notification.error(option)
  }

  getLocation (setPosition: (position: any) => void): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPosition(position)
        },
        () => {
          setPosition(null)
        }
      )
    }
  }
}
