import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createElementBlock("span", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setHome && _ctx.setHome(...args))),
    style: {"cursor":"pointer"}
  }, [
    _createVNode(_component_a_image, {
      id: "image",
      src: _ctx.getLogo(),
      preview: false,
      style: _normalizeStyle(_ctx.style)
    }, null, 8, ["src", "style"])
  ]))
}