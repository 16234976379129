
import { defineComponent, ref, h } from 'vue'
import { mapActions, mapState } from 'vuex'
import { ListEnvelope } from '@/services/responses/ListEnvelopeResponse'
import { LoadingOutlined } from '@ant-design/icons-vue'
import DataTable from '@/views/home/dataTable/index.vue'
import PageTitle from '@/assets/components/PageTitle.vue'

export default defineComponent({
  name: 'HomeContent',
  components: {
    DataTable,
    PageTitle
  },
  data () {
    return {
      loading: false,
      indicator: h(LoadingOutlined, {
        style: {
          fontSize: '120px'
        },
        spin: true
      })
    }
  },
  computed: {
    ...mapState('sections', ['envelopes', 'showSpinContent']),
    envelops (): ListEnvelope[] {
      return this.envelopes !== null ? this.envelopes?.data : []
    },
    showSpin (): boolean {
      return this.showSpinContent
    }
  },
  methods: {
    ...mapActions('sections', [
        'setOpenNewDocument'
    ]),
    newDocument () {
      this.setOpenNewDocument(true)
    }
  }
})
