
  import { defineComponent, reactive, ref, h, createVNode } from 'vue'
  import { EnvelopeService } from '@/services/EnvelopeService'
  import { ValidateService } from '@/services/ValidateService'
  import { CountrieService } from '@/services/CountrieService'
  import { AccountService } from '@/services/AccountService'
  import { SignatureService } from '@/services/SignatureService'
  import { TokenService } from '@/services/TokenService'
  import { UserService } from '@/services/UserService'

  import {
    MoreOutlined,
    InfoCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SolutionOutlined,
    EditOutlined,
    LinkOutlined,
    RetweetOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
    FilterFilled,
    CopyOutlined
  } from '@ant-design/icons-vue'
  import { notification, Modal } from 'ant-design-vue'

  interface EnvelopeRecipient {
    Email: string
    PartName: string
    key: number
    Order: number
    PaperSignatureId: number
    isWhatsapp: boolean
    Phone: string
    PhoneCode: string
    PhoneMask: string
    PhoneFlag: string
    isDocCPF: boolean
    isDocRG: boolean
    isDocOther: boolean
    docMask: string
    Document: string
    DocumentType: string
    iWantToSign: boolean
    isPaperSign: boolean
    isScheduleRecipient: boolean
    fromSchedule: boolean
  }

  export default defineComponent({
    name: 'EnvelopeDetailsPages',
    props: {
        hashIdDocument: {
            type: String
        },
        detailsDocument: {
            type: Object
        }
    },
    components: {
      MoreOutlined,
      InfoCircleOutlined,
      CheckCircleOutlined,
      CloseCircleOutlined,
      SolutionOutlined,
      EditOutlined,
      LinkOutlined,
      RetweetOutlined,
      CloseOutlined,
      FilterFilled,
      CopyOutlined
    },
    data: () => ({
        accountService: ref<AccountService>(),
        envelopeService: ref<EnvelopeService>(),
        signatureService: ref<SignatureService>(),
        userService: ref<UserService>(),
        userData: ref<any>(null),
        isModeEdit: ref<boolean>(false),
        isFilter: ref<boolean>(false),
        isValidCpf: ref<boolean>(false),
        isSysAdmin: ref<boolean>(false),
        isAdmin: ref<boolean>(false),
        isOwnerEnvelope: ref<boolean>(false),
        tempSignatureRequests: ref<any>([]),
        envelopeRecipient: reactive<EnvelopeRecipient>(
          {
            Email: '',
            PartName: '',
            key: Date.now(),
            Order: 1,
            PaperSignatureId: 1,
            isWhatsapp: false,
            Phone: '',
            PhoneCode: '+55',
            PhoneMask: '(##) # ####-####',
            PhoneFlag: 'fi fi-br',
            isDocCPF: true,
            isDocRG: false,
            isDocOther: false,
            docMask: '###.###.###-##',
            Document: '',
            DocumentType: 'CPF',
            iWantToSign: false,
            isPaperSign: true,
            isScheduleRecipient: false,
            fromSchedule: false
          }
        ),
        countriesWhatsapp: ref<any>(null),
        hashId: ref<any>(null),
        envelopeId: ref<any>(null),
        isEnvelopeOrder: ref<boolean>(false),
        isShowLinkSignRecipient: ref<boolean>(false),
        linkSignRecipient: ref<any>(null),
        recipientPartName: ref<any>('')
    }),
    watch: { },
    computed: {
        signatureRequests (): any[] {
            return this.tempSignatureRequests.length > 0 ? this.tempSignatureRequests : this.detailsDocument?.signatureRequests
        }
    },
    async mounted () {
        const userDataByToken = TokenService.getUserData()
        const countrieService = CountrieService.getInstance()
        this.countriesWhatsapp = countrieService.getCountries()
        this.envelopeService = EnvelopeService.getInstance()
        this.accountService = AccountService.getInstance()
        this.signatureService = SignatureService.getInstance()
        this.userService = UserService.getInstance()
        this.userData = await this.userService.getDataUserById(userDataByToken.userId)
        this.hashId = this.hashIdDocument
        this.envelopeId = this.detailsDocument?.id
        this.isEnvelopeOrder = this.detailsDocument?.isOrder
        this.isSysAdmin = this.userData.isSysAdmin
        this.isAdmin = (this.userData.userAccounts[0].type === 2)
        this.isOwnerEnvelope = this.userData.userAccounts[0].userId === this.detailsDocument?.createdById
    },
    beforeUpdate () {
        this.envelopeId = this.detailsDocument?.id
    },
    async updated () {
        this.isOwnerEnvelope = this.userData.userAccounts[0].userId === this.detailsDocument?.createdById
        this.isEnvelopeOrder = this.detailsDocument?.isOrder
        this.hashId = this.hashIdDocument
    },
    methods: {
        validateCPF (cpf: string, isCpf: boolean, isShowMessage: boolean) {
          if (cpf.length === 14 && isCpf) {
            this.isValidCpf = ValidateService.isValidCpf(cpf)
            if (!this.isValidCpf) {
              if (isShowMessage) {
                notification.warning({
                  message: 'Cpf inválido',
                  description: 'O CPF inserido é inválido, favor corrigir.'
                })
              }
              return false
            }
          } else if (cpf.length > 14) {
            if (isShowMessage) {
              notification.warning({
                message: 'Cpf inválido',
                description: 'O CPF inserido possui caracteres há mais, favor corrigir.'
              })
            }
            return false
          }
        },
        getClassStatus (item: any) {
            if (item.status === 1 || item.status === 5) return 'pending'
            if (item.status === 2) return 'signed'
            if (item.status === 3 || item.status === 4) return 'rejected'
        },
        editRecipient (item: any) {
            this.isModeEdit = true
            this.signatureRequests.forEach((el: any) => {
                if ((el.email === item.email && el.phone === '') || (el.phone === item.phone && el.email === '')) {
                    el.isModeEdit = true
                } else {
                    el.isModeEdit = false
                }
            })

            this.envelopeRecipient.DocumentType = item.documentType
            this.changeTypeDocRecipient()

            if (item.email !== '') {
                this.envelopeRecipient.Email = item.email
            } else {
                const splitPhone = item.phone.split('(')
                const phoneCode = splitPhone[0].trim()

                this.envelopeRecipient.isWhatsapp = true
                this.envelopeRecipient.PhoneCode = phoneCode
                this.changeCountryWhatsRecipient()
                this.envelopeRecipient.Phone = '(' + splitPhone[1].trim()
            }

            this.envelopeRecipient.PartName = item.partName
            this.envelopeRecipient.Document = item.document
            this.envelopeRecipient.PaperSignatureId = item.paperSignatureId
            this.envelopeRecipient.Order = item.order

            if (item.status === 5) {
                this.envelopeRecipient.isDocCPF = false
                this.envelopeRecipient.isDocRG = false
                this.envelopeRecipient.isDocOther = false
                this.envelopeRecipient.isPaperSign = false
            }
        },
        changeTypeDocRecipient () {
          const docType = this.envelopeRecipient.DocumentType
          if (docType === 'CPF') {
            this.envelopeRecipient.isDocCPF = true
            this.envelopeRecipient.isDocRG = false
            this.envelopeRecipient.isDocOther = false
            this.envelopeRecipient.docMask = '###.###.###-##'
            this.envelopeRecipient.Document = ''
          }
          if (docType === 'RG') {
            this.envelopeRecipient.isDocCPF = false
            this.envelopeRecipient.isDocRG = true
            this.envelopeRecipient.isDocOther = false
            this.envelopeRecipient.docMask = ''
            this.envelopeRecipient.Document = ''
          }
          if (docType === 'OTHER' || docType === 'OUTRO') {
            this.envelopeRecipient.isDocCPF = false
            this.envelopeRecipient.isDocRG = false
            this.envelopeRecipient.isDocOther = true
            this.envelopeRecipient.docMask = ''
            this.envelopeRecipient.Document = ''
          }
        },
        changeTypeChannelRecipient () {
          this.envelopeRecipient.Email = ''
          this.envelopeRecipient.Phone = ''
          this.envelopeRecipient.PhoneCode = '+55'
          this.envelopeRecipient.PhoneMask = '(##) # ####-####'
          this.envelopeRecipient.PhoneFlag = 'fi fi-br'
        },
        changeCountryWhatsRecipient () {
          const country = this.countriesWhatsapp.filter((v: any) => v.ddi === this.envelopeRecipient.PhoneCode)
          if (country.length > 0) {
            this.envelopeRecipient.Phone = ''
            this.envelopeRecipient.PhoneMask = country[0].mask
            this.envelopeRecipient.PhoneFlag = country[0].flag
          }
        },
        closeModalDetails () {
            this.isModeEdit = false
            this.tempSignatureRequests = []
            this.resetEnvelopeRecipient()
            this.$emit('closeModalDetails')
        },
        resetEnvelopeRecipient () {
          this.envelopeRecipient = {
            Email: '',
            PartName: '',
            key: Date.now(),
            Order: 1,
            PaperSignatureId: 1,
            isWhatsapp: false,
            Phone: '',
            PhoneCode: '+55',
            PhoneMask: '(##) # ####-####',
            PhoneFlag: 'fi fi-br',
            isDocCPF: true,
            isDocRG: false,
            isDocOther: false,
            docMask: '###.###.###-##',
            Document: '',
            DocumentType: 'CPF',
            iWantToSign: false,
            isPaperSign: true,
            isScheduleRecipient: false,
            fromSchedule: false
          }
        },
        cancelActionInEditRecipient () {
            const msg = 'Ao cancelar as informações preenchidas serão perdidas e os dados não serão alterados!'
            Modal.confirm({
                title: 'Deseja realmente cancelar?',
                icon: createVNode(ExclamationCircleOutlined),
                content: createVNode('div', { }, msg),
                centered: true,
                okText: 'Sim Desejo',
                cancelText: 'Não',
                onOk: () => {
                    this.isModeEdit = false
                    this.resetEnvelopeRecipient()
                    this.signatureRequests.forEach((el: any) => {
                        el.isModeEdit = false
                    })
                }
            })
        },
        async reloadListRecipients (hashId: any) {
            this.resetEnvelopeRecipient()
            this.isModeEdit = false
            const resReload = await EnvelopeService.getInstance().detailsEnvelope(hashId)
            this.tempSignatureRequests = resReload.signatureRequests
            this.tempSignatureRequests.forEach((el: any) => {
                el.isModeEdit = false
            })
        },
        async filterRecipientByStatus (status: any) {
            const resFilter = await EnvelopeService.getInstance().detailsEnvelope(this.hashId)
            this.isFilter = true
            if (status === 1 || status === 2) {
                const resFilterStatus = resFilter.signatureRequests.filter((x: any) => x.status === status)
                if (resFilterStatus.length > 0) {
                    this.tempSignatureRequests = resFilterStatus
                } else {
                    this.tempSignatureRequests = resFilter.signatureRequests
                }
            } else {
                this.tempSignatureRequests = resFilter.signatureRequests
            }
        },
        async saveRecipient () {
            const envelopeId = this.detailsDocument?.id
            const recipientInModeEdit = this.signatureRequests.filter((x: any) => x.isModeEdit)
            const recipientId = recipientInModeEdit[0].id
            let isDocumentValid = true
            let isChangedChannel = false

            if (this.envelopeRecipient.Phone === '' && this.envelopeRecipient.isWhatsapp) {
                notification.warning({
                    message: 'Alerta!',
                    description: 'O campo telefone não foi preenchido, favor corrigir.'
                })
                return false
            }

            if (this.envelopeRecipient.isDocCPF) {
                isDocumentValid = ValidateService.isValidCpf(this.envelopeRecipient.Document)
            } else {
                isDocumentValid = true
            }

            if (isDocumentValid) {
                if (this.envelopeRecipient.PhoneCode !== '' && this.envelopeRecipient.Email === '') {
                    const splitPhone = this.envelopeRecipient.Phone.split('(')
                    const phoneCode = splitPhone[0].trim()
                    if (splitPhone[0].length === 0) {
                        this.envelopeRecipient.Phone = this.envelopeRecipient.PhoneCode + ' ' + this.envelopeRecipient.Phone
                    }
                    // Validate is change channel
                    const splitPhoneOld = recipientInModeEdit[0].phone.split('(')
                    const phone = '(' + splitPhone[1]
                    const phoneOld = '(' + splitPhoneOld[1]
                    if (phone !== phoneOld) {
                        isChangedChannel = true
                    }
                } else {
                    // Validate is change channel
                    if (this.envelopeRecipient.Email !== recipientInModeEdit[0].email) {
                        isChangedChannel = true
                    }
                }

                const requestSignatureUpdate = {
                    EnvelopeId: envelopeId,
                    RequestSignatureId: recipientId,
                    RequestSignature: JSON.stringify(this.envelopeRecipient),
                    UserName: this.userData.name
                }

                const res = await this.envelopeService?.changeRecipientEnvelope(requestSignatureUpdate)
                if (res !== null) {
                    if (this.isEnvelopeOrder) {
                        const beforeItemSign: any = this.signatureRequests.filter((x: any) => x.order === (this.envelopeRecipient.Order - 1))
                        if (beforeItemSign.length > 0 && beforeItemSign[0].status === 2) {
                            const formData = {
                                idEnvelope: envelopeId,
                                idSignatureRequest: recipientId
                            }
                            if (isChangedChannel) await this.signatureService?.resendNotificationRecipient(formData)
                        } else {
                            if (beforeItemSign.length === 0) {
                                const formData = {
                                    idEnvelope: envelopeId,
                                    idSignatureRequest: recipientId
                                }
                                if (isChangedChannel) await this.signatureService?.resendNotificationRecipient(formData)
                            }
                        }
                    } else {
                        const formData = {
                            idEnvelope: envelopeId,
                            idSignatureRequest: recipientId
                        }
                        if (isChangedChannel) await this.signatureService?.resendNotificationRecipient(formData)
                    }

                    notification.success({
                        message: 'Atualizado com sucesso!',
                        description: 'Os dados do destinatário foram atualizados com sucesso.'
                    })
                    this.reloadListRecipients(this.hashId)
                }
            } else {
                notification.warning({
                    message: 'Cpf inválido',
                    description: 'O CPF inserido é inválido, favor corrigir.'
                })
            }
        },
        async showLinkSignRecipient (envelopeId: any, recipient: any) {
            this.recipientPartName = ValidateService.titleize(recipient.partName)
            const formData = {
                idEnvelope: envelopeId,
                idSignatureRequest: recipient.id
            }
            const res = await this.signatureService?.getLinkSignatureRecipient(formData)
            this.linkSignRecipient = res.link
            this.isShowLinkSignRecipient = true
        },
        resendNotificationRecipient (envelopeId: any, recipient: any) {
            const recipientId = recipient.id
            const msg = 'Ao confirmar uma notificação será enviada para este destinatário.'
            if (this.isEnvelopeOrder) {
                const beforeItemSign: any = this.signatureRequests.filter((x: any) => x.order === (recipient.order - 1))
                if (beforeItemSign.length > 0 && beforeItemSign[0].status === 2) {
                    Modal.confirm({
                        title: 'Deseja realmente reenviar?',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: createVNode('div', { }, msg),
                        okText: 'Reenviar',
                        cancelText: 'Cancelar',
                        centered: true,
                        onOk: async () => {
                            const formData = {
                                idEnvelope: envelopeId,
                                idSignatureRequest: recipientId
                            }
                            await this.signatureService?.resendNotificationRecipient(formData)
                        }
                    })
                } else {
                    if (beforeItemSign.length === 0) {
                        Modal.confirm({
                            title: 'Deseja realmente reenviar?',
                            icon: createVNode(ExclamationCircleOutlined),
                            content: createVNode('div', { }, msg),
                            okText: 'Reenviar',
                            cancelText: 'Cancelar',
                            centered: true,
                            onOk: async () => {
                                const formData = {
                                    idEnvelope: envelopeId,
                                    idSignatureRequest: recipientId
                                }
                                await this.signatureService?.resendNotificationRecipient(formData)
                            }
                        })
                    } else {
                        notification.warning({
                            message: 'Envelope Ordenado!',
                            description: 'Este envelope é do tipo ordenado, sendo assim respeite a ordem de assinatura para reenviar o link.'
                        })
                    }
                }
            } else {
                Modal.confirm({
                    title: 'Deseja realmente reenviar?',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: createVNode('div', { }, msg),
                    okText: 'Reenviar',
                    cancelText: 'Cancelar',
                    centered: true,
                    onOk: async () => {
                        const formData = {
                            idEnvelope: envelopeId,
                            idSignatureRequest: recipientId
                        }
                        await this.signatureService?.resendNotificationRecipient(formData)
                    }
                })
            }
        },
        async copyLinkRecipient () {
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(this.linkSignRecipient)
                notification.success({
                    message: 'O link foi copiado com sucesso!'
                })
            } else {
                const textarea = document.createElement('textarea')
                textarea.value = this.linkSignRecipient
                textarea.style.position = 'absolute'
                textarea.style.left = '-99999999px'
                document.body.prepend(textarea)
                textarea.select()
                try {
                    document.execCommand('copy')
                } catch (err) {
                    console.log(err)
                } finally {
                    textarea.remove()
                    notification.success({
                        message: 'O link foi copiado com sucesso!'
                    })
                }
            }
        }
    }
  })
