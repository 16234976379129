
    import { defineComponent, h, ref } from 'vue'
    import { mapActions, mapState } from 'vuex'
    import { ListSchedule } from '@/services/responses/ListScheduleResponse'
    import { LoadingOutlined } from '@ant-design/icons-vue'
    import ScheduleDataTable from '@/views/schedule/dataTable/index.vue'
    import { TokenService } from '@/services/TokenService'

    export default defineComponent({
        name: 'ScheduleContent',
        components: {
            ScheduleDataTable
        },
        data () {
          return {
            loading: false,
            indicator: h(LoadingOutlined, {
                style: {
                    fontSize: '120px'
                },
                spin: true
            }),
            activeKey: ref('1'),
            userId: ref<any>(null)
          }
        },

        computed: {
            ...mapState('sections', ['showSpinContent']),
            ...mapState('schedules', ['schedules']),
            getSchedules (): ListSchedule[] {
                return this.schedules !== null ? this.schedules?.data : []
            },
            showSpin (): boolean {
                return this.showSpinContent
            }
        },

        async mounted () {
            await this.fetchSchedules()
            this.userId = TokenService.getUserData().userId
        },

        methods: {
            ...mapActions('schedules', ['fetchSchedules', 'fetchScheduleFavorites']),

            async onChangeTabs () {
                this.setTabIndex(this.activeKey)
                if (this.activeKey === '1') {
                    await this.fetchSchedules()
                } else {
                    await this.fetchScheduleFavorites({ userId: this.userId })
                }
            },
            createOrEdit (item: any, favorites: boolean) {
                this.$emit('createOrEdit', item, favorites)
            },

            contactsToEnvelope (item: any) {
                this.$emit('contactsToEnvelope', item)
            },
            setTabIndex (tab: any) {
                this.$emit('setTabIndex', tab)
            }
        }
    })
