
  import { defineComponent } from 'vue'
  import { mapState } from 'vuex'
  import {
    FileTextOutlined,
    LockOutlined
  } from '@ant-design/icons-vue'

  export default defineComponent({
    name: 'PageTitle',
    components: {
      FileTextOutlined,
      LockOutlined
    },
    computed: {
      ...mapState('sections', ['currentStatus', 'folderName'])
    }
  })

