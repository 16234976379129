import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f56bfa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "document" }
const _hoisted_2 = { class: "document-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Header, { page: "document" }),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ]))
}