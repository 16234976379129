
import { defineComponent, ref } from 'vue'
import { FolderService } from '@/services/FolderService'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'FolderSelectNewDocument',
  components: { },
  props: {
    selected: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      folderService: ref<FolderService | null>(null),
      cofreSelected: ref<any>(''),
      selectData: ref<any>([])
    }
  },
  computed: {
    ...mapState('sections', ['folderId'])
  },
  methods: {
    ...mapActions('sections', [
      'setFolderId',
      'setFolderName'
    ]),
    async getFolder () {
      const folders = await this.folderService?.getSelectView()
      this.selectData = folders.childrens
    },
    getDataForCofreSelected (cofreID: any) {
      const filter = this.selectData.filter((x: any) => x.id === cofreID)
      const currentCofre = {
        id: filter[0].id,
        name: filter[0].name
      }
      return currentCofre
    }
  },
  async mounted () {
    this.folderService = FolderService.getInstance()
    await this.getFolder()

    if (!localStorage.getItem('folderId') || localStorage.getItem('folderId') === '0') {
      this.setFolderId(parseInt(this.selectData[0].id))
      this.setFolderName(this.selectData[0].name)
      this.cofreSelected = this.selectData[0].name
    } else {
      const valueInSelected = (this.selected * 1)
      const filter = this.selectData.filter((x: any) => x.id === valueInSelected)
      this.setFolderId(parseInt(filter[0].id))
      this.setFolderName(filter[0].name)
      this.cofreSelected = filter[0].name
    }
  }
})
