import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import './themes/merge.less'
import './registerServiceWorker'
import router from './router'
import store from './views/store'
import Maska from 'maska'

createApp(App).use(Antd).use(store).use(router).use(Maska).mount('#app')
