
import { defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import { EnvelopeService } from '@/services/EnvelopeService'
import {
  HomeOutlined,
  FileProtectOutlined,
  ExceptionOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  name: 'InfoSignature',
  props: {
    collapsed: Boolean
  },
  components: {
    HomeOutlined,
    FileProtectOutlined,
    ExceptionOutlined
  },
  data () {
    return {
      isActive: false,
      pending: ref<number>(),
      signed: ref<number>(),
      canceled: ref<number>(),
      selectedKeys: ref<string[]>(['1']),
      envelopeService: ref<EnvelopeService>(),
      isHomeActive: ref<boolean>(false),
      isSignedActive: ref<boolean>(false),
      isCanceledActive: ref<boolean>(false)
    }
  },
  watch: {
    treeSelected () {
      if (this.treeSelected[0] === '0') {
        this.isHomeActive = false
        this.isSignedActive = false
        this.isCanceledActive = false
      }
    }
  },
  async mounted () {
    this.envelopeService = EnvelopeService.getInstance()
    const infos = await this.envelopeService.GetInfoEnvelope()
    this.pending = infos.countEnvelopesPending
    this.signed = infos.countEnvelopesCompleted
    this.canceled = infos.countEnvelopesCanceled
    this.isHomeActive = true
    this.isSignedActive = false
    this.isCanceledActive = false
    await this.setHomePageInMount()
  },
  updated () {
    if (this.currentStatus === 6 || this.currentStatus === 1) {
      this.isHomeActive = false
      this.isSignedActive = false
      this.isCanceledActive = false
    }
  },
  methods: {
    ...mapActions('sections', [
        'fetchEnvelopesByUser',
        'fetchEnvelopesINeedToSign',
        'fetchEnvelopesOthersPendingSign',
        'setOpenNewDocument',
        'setTreeSelected',
        'setStatusList',
        'setHomePage',
        'setCurrentStatus',
        'setPagePagination'
    ]),
    async getEnvelopesByStatus (status: number) {
      this.setActiveNavigation(status)
      this.setHomePage(false)
      this.setCurrentStatus(status)
      this.setTreeSelected([status.toString()])
      const page = 1
      this.setPagePagination(1)
      this.setStatusList(status)
      await this.fetchEnvelopesByUser({ page: page, size: null, status: status })
    },
    async getHomePage () {
      this.setActiveNavigation(1)
      await this.setHomePageInMount()
    },
    async setHomePageInMount () {
      this.setTreeSelected(['1'])
      this.setHomePage(true)
      this.setCurrentStatus(null)
      this.setPagePagination(1)
      await this.fetchEnvelopesINeedToSign({ page: 1, size: 3, status: 1, limit: 3 })
      await this.fetchEnvelopesOthersPendingSign({ page: 1, size: 3, status: 6, limit: 3 })
    },
    setActiveNavigation (status: number) {
      if (status === 1) {
        this.isHomeActive = true
        this.isSignedActive = false
        this.isCanceledActive = false
      } else if (status === 2) {
        this.isHomeActive = false
        this.isSignedActive = true
        this.isCanceledActive = false
      } else if (status === 3) {
        this.isHomeActive = false
        this.isSignedActive = false
        this.isCanceledActive = true
      } else {
        this.isHomeActive = true
        this.isSignedActive = false
        this.isCanceledActive = false
      }
    }
  },
  computed: {
    ...mapState('sections', [
      'treeSelected',
      'pagePagination',
      'currentStatus'
    ])
  }
})
