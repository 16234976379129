
import { defineComponent, ref } from 'vue'
import {
  DeleteOutlined,
  UserSwitchOutlined,
  LinkOutlined,
  CopyOutlined
} from '@ant-design/icons-vue'
import { UserService } from '@/services/UserService'
import { TokenService } from '@/services/TokenService'
import { notification } from 'ant-design-vue'

export default defineComponent({
  name: 'AccountConfiguration',
  components: {
    DeleteOutlined,
    UserSwitchOutlined,
    LinkOutlined,
    CopyOutlined
  },
  data: () => ({
    service: null as any,
    userData: ref<any>(null),
    isActionAdminOrSysAdmin: ref<boolean>(false),
    isSysAdmin: ref<boolean>(false),
    isAdmin: ref<boolean>(false),
    typeAccount: ref<any>(null),
    formValues: {
      email: '' as string
    },
    accounts: [] as any,
    linkUser: ref<any>(null),
    isShowLinkUser: false,
    urlActive: ref(''),
    userDataByToken: [] as any
  }),
  async created () {
    this.service = UserService.getInstance()

    this.userDataByToken = TokenService.getUserData()
    this.userData = await this.service.getDataUserById(this.userDataByToken.userId)
    this.accounts = await this.service.getAllUsers(this.userDataByToken.accountId)
    this.typeAccount = this.userData.userAccounts[0].type
    this.isSysAdmin = this.userData.isSysAdmin
    if (this.typeAccount === 2 || this.isSysAdmin) this.isActionAdminOrSysAdmin = true
    if (this.typeAccount === 2) this.isAdmin = true
  },
  beforeUnmount () {
    this.accounts = []
  },
  methods: {
    async removeUser (id: number) {
      await this.service.deleteUser(id)
      this.accounts = await this.service.getAllUsers(this.userDataByToken.accountId)
    },
    async addUser () {
      try {
        await this.service.createUser(this.formValues)
        notification.success({
          message: 'Usuário adicionado',
          description: 'Um convite foi enviado para o e-mail cadastrado'
        })
        this.formValues.email = ''
        this.accounts = await this.service.getAllUsers(this.userDataByToken.accountId)
      } catch (err) {
        console.log(err)
      }
    },
    async changeTypeUser (id: number) {
      await this.service.changeTypeUser(id)
      this.accounts = await this.service.getAllUsers(this.userDataByToken.accountId)
    },
    async getLinkToUser (id: number) {
      this.linkUser = await this.service.getLinkUser(id)
      this.urlActive = this.linkUser.linkActive

      this.isShowLinkUser = true
    },
    async copyLinkUrl () {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(this.urlActive)
        notification.success({
            message: 'O link foi copiada com sucesso!'
        })
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = this.urlActive
        textarea.style.position = 'absolute'
        textarea.style.left = '-99999999px'
        document.body.prepend(textarea)
        textarea.select()
        try {
            document.execCommand('copy')
        } catch (err) {
            console.log(err)
        } finally {
            textarea.remove()
            notification.success({
                message: 'O link foi copiada com sucesso!'
            })
        }
      }
    }
  }
})
