export interface UserData {
  userId: number,
  accountId: number,
  userEmail: string,
  logo: string,
}

export class TokenService {
  public static setToken (token: string): void {
    localStorage.setItem('token', btoa(token))
  }

  public static getToken (): string {
    const token = localStorage.getItem('token')
    return atob(token || '')
  }

  public static setUserData (userData:UserData): void {
    localStorage.setItem('userData', btoa(JSON.stringify(userData)))
  }

  public static getUserData () : UserData {
    const userData = localStorage.getItem('userData')
    if (userData) return JSON.parse(atob(userData))
    else {
      return {
        userId: 0,
        accountId: 0,
        userEmail: '',
        logo: ''
      }
    }
  }

  public static clear () : void {
    localStorage.clear()
  }
}
