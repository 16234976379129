
import { defineComponent, ref } from 'vue'

import {
  BankOutlined,
  UserOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  name: 'ThumbPerfil',
  components: {
    BankOutlined,
    UserOutlined
  },
  props: {
    accountType: {
      type: Number
    }
  },
  data: () => ({
    style: ''
  }),
  methods: {
    getThumbPerfil (): string {
      return require('@/assets/thumb-perfil.png')
    }
  }
})
