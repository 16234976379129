import * as forge from 'node-forge'

export class RsaCryptographyService {
  public static generateKeys (): { publicKey: string; privateKey: string } {
    // Generate an RSA key pair
    const { privateKey, publicKey } = forge.pki.rsa.generateKeyPair(2048)
    // Export the public key in SubjectPublicKeyInfo (Base64) format
    const publicKeyInfo = forge.pki.publicKeyToAsn1(publicKey)
    const publicKeyDer = forge.asn1.toDer(publicKeyInfo).getBytes()
    const publicKeyBase64 = forge.util.encode64(publicKeyDer)
    // Export the private key in PKCS#8 (Base64) format
    const privateKeyDer = forge.pki.privateKeyToAsn1(privateKey)
    const privateKeyDerBytes = forge.asn1.toDer(privateKeyDer).getBytes()
    const privateKeyBase64 = forge.util.encode64(privateKeyDerBytes)

    return {
      publicKey: publicKeyBase64,
      privateKey: privateKeyBase64
    }
  }

  public static encrypt (data: string, publicKey: string): string {
    // Decode public key from Base64
    const publicKeyDer = forge.util.decode64(publicKey)
    const publicKeyAsn1 = forge.asn1.fromDer(publicKeyDer)
    const forgePublicKey = forge.pki.publicKeyFromAsn1(publicKeyAsn1)
    // Encrypt data
    const encryptedBytes = forgePublicKey.encrypt(data, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: { md: forge.md.sha256.create() }
    })
    // Return Base64 encrypted data
    return forge.util.encode64(encryptedBytes)
  }

  public static decrypt (encryptedData: string, privateKey: string): string {
    // Decode Private Key from Base64
    const privateKeyDer = forge.util.decode64(privateKey)
    const privateKeyAsn1 = forge.asn1.fromDer(privateKeyDer)
    const forgePrivateKey = forge.pki.privateKeyFromAsn1(privateKeyAsn1)
    // Decode Base64 encrypted data
    const decodedBytes = forge.util.decode64(encryptedData)
    // Decrypt the data
    const decryptedBytes = forgePrivateKey.decrypt(decodedBytes, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: { md: forge.md.sha256.create() }
    })

    return decryptedBytes
  }
}
