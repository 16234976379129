
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import router from '@/router'

export default defineComponent({
  name: 'LogoCertificata',
  data: () => ({
    style: ''
  }),
  methods: {
    ...mapActions('sections', ['setCurrentStatus', 'setHomePage']),
    getLogo (): string {
      return require('@/assets/logo-certificata.png')
    },
    setHome () {
      this.setHomePage(true)
      this.setCurrentStatus(null)
      router.replace({ name: 'home' })
    }
  }
})
