
import { defineComponent, ref } from 'vue'
import { FolderService } from '@/services/FolderService'
import { mapActions, mapState } from 'vuex'
import { LockOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'FolderSelectHome',
  components: {
    LockOutlined
  },
  data () {
    return {
      folderService: ref<FolderService | null>(null),
      cofreSelected: ref<any>('0'),
      selectData: ref<any>([])
    }
  },
  watch: {
    treeSelected () {
      if (this.treeSelected[0] !== '0') {
        this.cofreSelected = 0
      }
    }
  },
  computed: {
    ...mapState('sections', ['folderId', 'currentStatus', 'treeSelected'])
  },
  methods: {
    ...mapActions('sections', [
      'setFolderId',
      'setFolderName'
    ]),
    async getFolder () {
      const folders = await this.folderService?.getSelectView()
      this.selectData = folders.childrens
    },
    getDataForCofreSelected (cofreID: any) {
      const filter = this.selectData.filter((x: any) => x.id === cofreID)
      const currentCofre = {
        id: filter[0].id,
        name: filter[0].name
      }
      return currentCofre
    }
  },
  async mounted () {
    this.folderService = FolderService.getInstance()
    await this.getFolder()
    this.cofreSelected = 0
    if (!localStorage.getItem('folderId')) {
      this.setFolderId(parseInt(this.selectData[0].id))
      this.setFolderName(this.selectData[0].name)
    }
  }
})
