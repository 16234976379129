
  import { defineComponent, ref } from 'vue'
  import { mapState, mapActions } from 'vuex'
  import { TokenService } from '@/services/TokenService'
  import LogoCertificata from '@/assets/components/LogoCertificata.vue'

  import {
    LogoutOutlined,
    SettingOutlined,
    ToolOutlined,
    DownOutlined,
    MenuOutlined,
    HomeOutlined
  } from '@ant-design/icons-vue'

  export default defineComponent({
    name: 'HeaderPages',
    components: {
      LogoutOutlined,
      SettingOutlined,
      ToolOutlined,
      DownOutlined,
      MenuOutlined,
      HomeOutlined,
      LogoCertificata
    },
    props: {
      page: {
        type: String
      }
    },
    data: () => ({
      isDocument: ref<boolean>(false),
      isConfiguration: ref<boolean>(false)
    }),
    async mounted () {
      if (this.page === 'document') {
        this.isDocument = true
        this.isConfiguration = false
      } else if (this.page === 'configuration') {
        this.isDocument = false
        this.isConfiguration = true
      }
    },
    computed: {
      ...mapState('sections', [
        'drawerMenu'
      ])
    },
    methods: {
      ...mapActions('sections', [
        'setDrawerMenu'
      ]),

      logout () {
        TokenService.clear()
        this.$router.push({
          name: 'authenticator'
        })
      }
    }
  })
