import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Perfil = _resolveComponent("Perfil")!
  const _component_Account = _resolveComponent("Account")!
  const _component_Vaults = _resolveComponent("Vaults")!
  const _component_Users = _resolveComponent("Users")!
  const _component_Customers = _resolveComponent("Customers")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: "main",
    style: {"min-height":"100vh !important"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Sidebar, { page: "configuration" }),
      _createVNode(_component_a_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_Header, { page: "configuration" }),
          _createVNode(_component_a_layout_content, {
            class: "body-content",
            style: { margin: '10px 16px', padding: '10px', background: '#fff' }
          }, {
            default: _withCtx(() => [
              (_ctx.currentInConfiguration === 0)
                ? (_openBlock(), _createBlock(_component_Perfil, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.currentInConfiguration === 1)
                ? (_openBlock(), _createBlock(_component_Account, { key: 1 }))
                : _createCommentVNode("", true),
              (_ctx.currentInConfiguration === 2)
                ? (_openBlock(), _createBlock(_component_Vaults, { key: 2 }))
                : _createCommentVNode("", true),
              (_ctx.currentInConfiguration === 3)
                ? (_openBlock(), _createBlock(_component_Users, { key: 3 }))
                : _createCommentVNode("", true),
              (_ctx.currentInConfiguration === 4)
                ? (_openBlock(), _createBlock(_component_Customers, { key: 4 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_Footer)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}