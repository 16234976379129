
 import { ArrowLeftOutlined } from '@ant-design/icons-vue'
import { defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import DrawerHistory from '@/views/home/history/index.vue'
import { ListEnvelope } from '@/services/responses/ListEnvelopeResponse'
import router from '@/router'

export default defineComponent({
  name: 'HeaderContent',
  components: {
    DrawerHistory,
    ArrowLeftOutlined
  },
  data () {
    return {
      value: ref<string>(''),
      loading: ref<boolean>(false)
    }
  },
  computed: {
    ...mapState('sections', ['envelopes', 'folderId', 'currentStatus', 'folderId']),
    envelops (): ListEnvelope[] {
      return this.envelopes !== null ? this.envelopes?.data : []
    }
  },
  methods: {
    ...mapActions('sections', [
      'fetchSearchEnvelope',
      'fetchEnvelopesByUser',
      'setOpenHistoric',
      'fetchHistoricEnvelops',
      'setTreeSelected',
      'fetchEnvelopesByVault',
      'setHomePage',
      'setCurrentStatus'
    ]),
    async openHistory () {
      let envelopeId: number[] | null = null
      if (this.folderId == null) {
        envelopeId = this.envelopes.data.map((x: any) => x.id)
      }
      await this.fetchHistoricEnvelops({
        folderId: this.folderId,
        envelopeId
      })
      this.setOpenHistoric(true)
    },
    async returnToVault () {
      this.setTreeSelected(['0'])
      this.loading = true
      if (this.currentStatus !== null) {
        await this.fetchEnvelopesByUser({
          page: 1,
          size: 9,
          status: this.currentStatus
        })
      } else {
        await this.fetchEnvelopesByVault({
          folderId: this.folderId,
          page: 1,
          size: 9
        })
      }
      this.loading = false
    },
    async onSearch (searchValue: string) {
      if (searchValue !== '') {
        this.setTreeSelected(['0'])
        this.loading = true
        await this.fetchSearchEnvelope({
          term: searchValue,
          status: this.currentStatus,
          folderId: this.folderId
        })
        this.loading = false
      } else {
        this.setTreeSelected(['0'])
        this.loading = true
        if (this.currentStatus !== null) {
          await this.fetchEnvelopesByUser({
            page: 1,
            size: 9,
            status: this.currentStatus
          })
        } else {
          await this.fetchEnvelopesByVault({
            folderId: this.folderId,
            page: 1,
            size: 9
          })
        }
        this.loading = false
      }
    }
  }
})
