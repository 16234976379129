export default () => ({
  accountInfo: {
    id: null,
    name: null,
    domain: null,
    cpfCnpj: null,
    zipCode: null,
    city: null,
    state: null,
    district: null,
    address: null,
    addressNumber: null,
    planName: null,
    type: null,
    integrationToken: null
  },

  userInfo: {
    id: null,
    name: null,
    email: null,
    cpf: null,
    birthDate: null
  },
  currentInConfiguration: 0,
  partnerCustomers: []
})
