
  import { defineComponent, reactive, ref, h } from 'vue'
  import { mapActions, mapState } from 'vuex'
  import { CountrieService } from '@/services/CountrieService'
  import { ScheduleService } from '@/services/ScheduleService'
  import { ValidateService } from '@/services/ValidateService'
  import { TokenService } from '@/services/TokenService'
  import HomeSchedule from '@/views/schedule/content/index.vue'
  import { notification, Modal } from 'ant-design-vue'
  import {
    UserAddOutlined
  } from '@ant-design/icons-vue'

  interface Schedule {
    Email: string
    PartName: string
    isWhatsapp: boolean
    Phone: string
    PhoneCode: string
    PhoneMask: string
    PhoneFlag: string
    isDocCPF: boolean
    isDocRG: boolean
    isDocOther: boolean
    docMask: string
    Document: string
    DocumentType: string
  }

  export default defineComponent({
    name: 'SchedulePages',
    components: {
        HomeSchedule,
        UserAddOutlined
    },
    data: () => ({
        scheduleService: ref<ScheduleService>(),
        valueSearch: ref<string>(''),
        isCreateOrEdit: ref<boolean>(false),
        fromCreate: ref<boolean>(false),
        isFavorites: ref<boolean>(false),
        dataCreateOrEdit: ref<any>(),
        isValidCpf: ref<boolean>(false),
        formSchedule: reactive<Schedule>(
            {
                Email: '',
                PartName: '',
                isWhatsapp: false,
                Phone: '',
                PhoneCode: '+55',
                PhoneMask: '(##) # ####-####',
                PhoneFlag: 'fi fi-br',
                isDocCPF: true,
                isDocRG: false,
                isDocOther: false,
                docMask: '###.###.###-##',
                Document: '',
                DocumentType: 'CPF'
            }
        ),
        countriesWhatsapp: ref<any>(null),
        contactsScheduleSelected: ref<any>(null),
        userId: ref<any>(null),
        userEmail: ref<any>(null)
    }),
    watch: {
      isCreateOrEdit (value) {
        if (!value) {
            this.formSchedule = {
                Email: '',
                PartName: '',
                isWhatsapp: false,
                Phone: '',
                PhoneCode: '+55',
                PhoneMask: '(##) # ####-####',
                PhoneFlag: 'fi fi-br',
                isDocCPF: true,
                isDocRG: false,
                isDocOther: false,
                docMask: '###.###.###-##',
                Document: '',
                DocumentType: 'CPF'
            }
        }
      }
    },
    computed: {
        ...mapState('schedules', ['schedules'])
    },
    async mounted () {
        const countrieService = CountrieService.getInstance()
        this.countriesWhatsapp = countrieService.getCountries()
        this.scheduleService = ScheduleService.getInstance()
        this.userId = TokenService.getUserData().userId
        this.userEmail = TokenService.getUserData().userEmail
    },

    methods: {
      ...mapActions('schedules', ['fetchSchedules', 'fetchScheduleFavorites', 'setSchedules']),
      async onSearch () {
        if (this.valueSearch !== '') {
            const newValuesInSearch = this.schedules.filter((x: any) =>
                x.name.toLowerCase().includes(this.valueSearch.toLowerCase()) ||
                x.collectSignature.toLowerCase().includes(this.valueSearch.toLowerCase())
            )
            this.setSchedules(newValuesInSearch)
        } else {
            if (this.isFavorites) {
                this.setSchedules(await this.scheduleService?.getScheduleFavorites(this.userId))
            } else {
                this.setSchedules(await this.scheduleService?.getSchedules())
            }
        }
      },
      setTabIndex (tab: any) {
        this.isFavorites = (tab !== '1')
      },
      contactsToEnvelope (item: any) {
        this.contactsScheduleSelected = item
      },
      createOrEdit (item: any, favorites: boolean) {
        this.isCreateOrEdit = true
        this.dataCreateOrEdit = item
        this.isFavorites = favorites
        if (item !== null) {
            this.setValuesForEditSchedule(item)
            this.fromCreate = false
        } else {
            this.fromCreate = true
        }
      },
      changeTypeChannelSchedule () {
        this.formSchedule.Email = ''
        this.formSchedule.Phone = ''
        this.formSchedule.PhoneCode = '+55'
        this.formSchedule.PhoneMask = '(##) # ####-####'
        this.formSchedule.PhoneFlag = 'fi fi-br'
      },
      changeCountryWhatsSchedule () {
        const country = this.countriesWhatsapp.filter((v: any) => v.ddi === this.formSchedule.PhoneCode)
        if (country.length > 0) {
          this.formSchedule.Phone = ''
          this.formSchedule.PhoneMask = country[0].mask
          this.formSchedule.PhoneFlag = country[0].flag
        }
      },
      changeTypeDocSchedule () {
        const docType = this.formSchedule.DocumentType
        if (docType === 'CPF') {
          this.formSchedule.isDocCPF = true
          this.formSchedule.isDocRG = false
          this.formSchedule.isDocOther = false
          this.formSchedule.docMask = '###.###.###-##'
          this.formSchedule.Document = ''
        }
        if (docType === 'RG') {
          this.formSchedule.isDocCPF = false
          this.formSchedule.isDocRG = true
          this.formSchedule.isDocOther = false
          this.formSchedule.docMask = ''
          this.formSchedule.Document = ''
        }
        if (docType === 'OTHER') {
          this.formSchedule.isDocCPF = false
          this.formSchedule.isDocRG = false
          this.formSchedule.isDocOther = true
          this.formSchedule.docMask = ''
          this.formSchedule.Document = ''
        }
      },
      validateCPF (cpf: string, isCpf: boolean, isShowMessage: boolean) {
      if (cpf.length === 14 && isCpf) {
        this.isValidCpf = ValidateService.isValidCpf(cpf)

        if (!this.isValidCpf) {
          if (isShowMessage) {
            notification.warning({
              message: 'CPF inválido!',
              description: 'O CPF inserido é inválido, favor corrigir.'
            })
          }

          return false
        }
      } else if (cpf.length > 14) {
        if (isShowMessage) {
          notification.warning({
            message: 'CPF inválido!',
            description: 'O CPF inserido possui caracteres há mais, favor corrigir.'
          })
        }

        return false
      }
    },
      setValuesForEditSchedule (item: any) {
        this.formSchedule.DocumentType = item.documentType
        this.changeTypeDocSchedule()

        if (item.signatureType === 'email') {
            this.formSchedule.Email = item.collectSignature
        } else {
            const splitPhone = item.collectSignature.split('(')
            const phoneCode = splitPhone[0].trim()

            this.formSchedule.isWhatsapp = true
            this.formSchedule.PhoneCode = phoneCode
            this.changeCountryWhatsSchedule()
            this.formSchedule.Phone = '(' + splitPhone[1].trim()
        }
        this.formSchedule.Document = item.requiredDocument
        this.formSchedule.PartName = item.name
      },
      async saveSchedule () {
        let validToSave = true
        let isValidCpf = true
        if (((this.formSchedule.isWhatsapp === false && this.formSchedule.Email === '') ||
          (this.formSchedule.isWhatsapp === true && this.formSchedule.Phone === '')) ||
           this.formSchedule.Document === '' || this.formSchedule.PartName === '') {
            validToSave = false
        }

        if (this.fromCreate && validToSave && (this.userEmail === this.formSchedule.Email)) {
            notification.error({
                message: 'Falha ao salvar na lista de contatos!',
                description: 'Não é possível salvar esse contato à sua lista. Por gentileza use o acesso rápido Incluir-me como signatário!'
            })
            return false
        }

        if (this.formSchedule.DocumentType === 'CPF') {
            isValidCpf = ValidateService.isValidCpf(this.formSchedule.Document)
        }

        if (validToSave) {
            if (isValidCpf) {
                const formData = new FormData()
                const collectSignature = this.formSchedule.isWhatsapp ? this.formSchedule.PhoneCode + ' ' + this.formSchedule.Phone : this.formSchedule.Email
                formData.append('name', this.formSchedule.PartName)
                formData.append('CollectSignature', collectSignature)
                formData.append('SignatureType', this.formSchedule.isWhatsapp ? 'phone' : 'email')
                formData.append('RequiredDocument', this.formSchedule.Document)
                formData.append('DocumentType', this.formSchedule.DocumentType)

                if (this.dataCreateOrEdit === null) {
                    const res = await this.scheduleService?.createScheduleContact(formData)
                    if (res !== null || res !== undefined) {
                        if (res.length === 0) {
                            notification.error({
                                message: 'Falha ao salvar na lista de contatos!',
                                description: 'Esse contato já está em sua lista'
                            })
                        } else {
                            this.isCreateOrEdit = false
                            if (this.isFavorites) {
                                await this.fetchScheduleFavorites({ userId: this.userId })
                            } else {
                                await this.fetchSchedules()
                            }
                            notification.success({
                                message: 'Contato adicionado!',
                                description: 'O contato foi adicionado com sucesso à sua lista'
                            })
                        }
                    } else {
                        notification.error({
                            message: 'Falha ao salvar na lista de contatos!',
                            description: 'Não foi possível adicionar o contato à sua lista'
                        })
                    }
                } else {
                    formData.append('id', this.dataCreateOrEdit.id)
                    const res = await this.scheduleService?.updateScheduleContact(formData)
                    if (res !== null || res !== undefined) {
                        this.isCreateOrEdit = false
                        if (this.isFavorites) {
                            await this.fetchScheduleFavorites({ userId: this.userId })
                        } else {
                            await this.fetchSchedules()
                        }
                        notification.success({
                            message: 'Contato atualizado!',
                            description: 'O contato foi atualizado com sucesso na sua lista'
                        })
                    } else {
                        notification.error({
                            message: 'Falha ao atualizar lista de contato!',
                            description: 'Não foi possível atualizar o contato na sua lista'
                        })
                    }
                }
            } else {
                notification.error({
                    message: 'Falha ao salvar na lista de contato!',
                    description: 'O CPF informado não é valido.'
                })
            }
        } else {
          notification.error({
            message: 'Falha ao salvar na lista de contato!',
            description: 'Os Campos: Nome, Documento e forma de contato são obrigatórios e precisam ser preenchidos.'
          })
        }
      },
      addContactsToEnvelope () {
        if (this.contactsScheduleSelected !== null && this.contactsScheduleSelected.length > 0) {
           this.$emit('addToEnvelope', this.contactsScheduleSelected)
        } else {
          notification.error({
            message: 'Falha ao selecionar!',
            description: 'Selecione os contatos da sua lista ou favoritos para adicionar ao envelope.'
          })
        }
      }
    }
  })
