import { ActionTree } from 'vuex'
import { EnvelopeService } from '@/services/EnvelopeService'

import types from './types'
const { GENERIC_MUTATION } = types

const setOpenSidebar = ({ commit, state }: any) => {
  const { openSidebar } = state

  commit(GENERIC_MUTATION, {
    property: 'openSidebar',
    value: !openSidebar
  })
}

const setEnvelops = ({ commit }: any, envelopes: any) => {
  commit(GENERIC_MUTATION, {
    property: 'envelopes',
    value: envelopes
  })
}

const setEnvelopsINeedToSign = ({ commit }: any, envelopesINeedToSign: any) => {
  commit(GENERIC_MUTATION, {
    property: 'envelopesINeedToSign',
    value: envelopesINeedToSign
  })
}

const setEnvelopsOthersPendingSign = ({ commit }: any, envelopesOthersPendingSign: any) => {
  commit(GENERIC_MUTATION, {
    property: 'envelopesOthersPendingSign',
    value: envelopesOthersPendingSign
  })
}

const fetchEnvelops = async (
  { dispatch }: any,
  { folderId, page, size }: any
) => {
  dispatch('setShowSpinContent', true)
  try {
    const envelopes = (await EnvelopeService.getInstance().listEnvelopes(
      folderId,
      page,
      size
    )) as any
    dispatch('setShowSpinContent', false)
    dispatch('setEnvelops', envelopes)
  } catch (err) {
    dispatch('setShowSpinContent', false)
  }
}

const fetchEnvelopesByVault = async (
    { dispatch }: any,
    { folderId, page, size }: any
  ) => {
    dispatch('setShowSpinContent', true)
    try {
      const envelopes = (await EnvelopeService.getInstance().listEnvelopesByVault(
        folderId,
        page,
        size
      )) as any
      dispatch('setShowSpinContent', false)
      dispatch('setEnvelops', envelopes)
    } catch (err) {
      dispatch('setShowSpinContent', false)
    }
}

const fetchEnvelopesByUser = async (
  { dispatch }: any,
  { page, size, status }: any
) => {
  dispatch('setShowSpinContent', true)
  try {
    const envelopes = (await EnvelopeService.getInstance().listEnvelopesByUser(
      page,
      size,
      status
    )) as any
    dispatch('setEnvelops', envelopes)
    dispatch('setShowSpinContent', false)
  } catch (err) {
    dispatch('setShowSpinContent', false)
  }
}

const fetchSearchEnvelope = async (
  { dispatch }: any,
  { term, status, folderId }: any
) => {
  dispatch('setShowSpinContent', true)
  try {
    const envelopes = (await EnvelopeService.getInstance().searchEnvelope(
      term, status, folderId
    )) as any
    dispatch('setShowSpinContent', false)
    dispatch('setEnvelops', envelopes)
  } catch (err) {
    dispatch('setShowSpinContent', false)
  }
}

const fetchEnvelopesINeedToSign = async (
    { dispatch }: any,
    { page, size, status, limit }: any
  ) => {
    dispatch('setShowSpinContent', true)
    try {
      const envelopesINeedToSign = (await EnvelopeService.getInstance().listEnvelopesByUser(
        page,
        size,
        status,
        limit
      )) as any
      dispatch('setEnvelopsINeedToSign', envelopesINeedToSign)
      dispatch('setShowSpinContent', false)
    } catch (err) {
      dispatch('setShowSpinContent', false)
    }
}

const fetchEnvelopesOthersPendingSign = async (
    { dispatch }: any,
    { page, size, status, limit }: any
  ) => {
    dispatch('setShowSpinContent', true)
    try {
      const envelopesOthersPendingSign = (await EnvelopeService.getInstance().listEnvelopesByUser(
        page,
        size,
        status,
        limit
      )) as any
      dispatch('setEnvelopsOthersPendingSign', envelopesOthersPendingSign)
      dispatch('setShowSpinContent', false)
    } catch (err) {
      dispatch('setShowSpinContent', false)
    }
}

const fetchHistoricEnvelops = async (
  { commit, dispatch }: any,
  { folderId, envelopeId }: any
) => {
  dispatch('setOpenHistoric', true)
  const historic = await EnvelopeService.getInstance().listInfoEnvelope(
    folderId,
    envelopeId
  )

  commit(GENERIC_MUTATION, {
    property: 'historicEnvelopes',
    value: historic
  })
}

const setFolderId = ({ commit }: any, folderId: any) => {
  localStorage.setItem('folderId', folderId)
  commit(GENERIC_MUTATION, {
    property: 'folderId',
    value: folderId
  })
}

const setFolderName = ({ commit }: any, folderName: string) => {
  localStorage.setItem('folderName', folderName)
  commit(GENERIC_MUTATION, {
    property: 'folderName',
    value: folderName
  })
}

const setPagePagination = ({ commit }: any, pagePaginaton: string) => {
  localStorage.setItem('pagePagination', pagePaginaton)
  commit(GENERIC_MUTATION, {
    property: 'pagePagination',
    value: pagePaginaton
  })
}

const setStatusList = ({ commit }: any, statusList: any) => {
  localStorage.setItem('statusList', statusList)
  commit(GENERIC_MUTATION, {
    property: 'statusList',
    value: statusList
  })
}

const setCurrentStatus = ({ commit }: any, currentStatus: any) => {
  commit(GENERIC_MUTATION, {
    property: 'currentStatus',
    value: currentStatus
  })
}

const setShowSpinContent = ({ commit }: any, value: boolean) => {
  commit(GENERIC_MUTATION, {
    property: 'showSpinContent',
    value
  })
}

const setOpenNewDocument = ({ commit }: any, value: boolean) => {
  commit(GENERIC_MUTATION, {
    property: 'openNewDocument',
    value
  })
}

const setOpenHistoric = ({ commit }: any, value: boolean) => {
  commit(GENERIC_MUTATION, {
    property: 'openHistoric',
    value
  })
}

const setTreeSelected = ({ commit }: any, value: any) => {
  commit(GENERIC_MUTATION, {
    property: 'treeSelected',
    value
  })
}

const setVaultSelected = ({ commit }: any, value: any) => {
    commit(GENERIC_MUTATION, {
        property: 'vaultSelected',
        value
    })
}

const setHomePage = ({ commit }: any, value: any) => {
  commit(GENERIC_MUTATION, {
    property: 'isHome',
    value
  })
}

const setDrawerMenu = ({ commit }: any, value: any) => {
  commit(GENERIC_MUTATION, {
    property: 'drawerMenu',
    value
  })
}

export default <ActionTree<any, any>>{
  setShowSpinContent,
  setOpenNewDocument,
  setOpenSidebar,
  setEnvelops,
  setFolderId,
  fetchEnvelops,
  fetchEnvelopesByVault,
  fetchHistoricEnvelops,
  fetchEnvelopesByUser,
  setDrawerMenu,
  setOpenHistoric,
  setFolderName,
  setPagePagination,
  setStatusList,
  setCurrentStatus,
  fetchSearchEnvelope,
  setTreeSelected,
  setHomePage,
  setEnvelopsINeedToSign,
  setEnvelopsOthersPendingSign,
  fetchEnvelopesOthersPendingSign,
  fetchEnvelopesINeedToSign,
  setVaultSelected
}
