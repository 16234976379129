
  import { defineComponent, ref } from 'vue'
  import { mapState, mapActions } from 'vuex'
  import { AccountService } from '@/services/AccountService'
  import SidebarLogo from '@/assets/components/SidebarLogo.vue'
  import FolderSelectView from '@/views/home/folder/selectCofreHome.vue'
  import InfoSignature from '@/views/home/infos/index.vue'
  import { UserService } from '@/services/UserService'
  import { TokenService } from '@/services/TokenService'

  import {
    IdcardOutlined,
    UserOutlined,
    CreditCardOutlined,
    TeamOutlined,
    FileAddOutlined,
    LockOutlined,
    ShareAltOutlined
  } from '@ant-design/icons-vue'

  export default defineComponent({
    name: 'SidebarPages',
    components: {
      SidebarLogo,
      FolderSelectView,
      InfoSignature,
      IdcardOutlined,
      UserOutlined,
      CreditCardOutlined,
      TeamOutlined,
      FileAddOutlined,
      LockOutlined,
      ShareAltOutlined
    },
    props: {
      page: {
        type: String
      }
    },
    data: () => ({
      isConfig: ref<boolean>(false),
      selecionado: 0 as number,
      isActionAdminOrSysAdmin: false as boolean,
      isActionPartner: false as boolean,
      itemObj: {
        collapsed: ref<boolean>(false)
      }
    }),
    async mounted () {
      if (this.page === 'configuration') {
        this.isConfig = true
        const userDataByToken = TokenService.getUserData()
        const userData = await UserService.getInstance().getDataUserById(userDataByToken.userId)
        const typeAccount = userData.userAccounts[0].type
        const isSysAdmin = userData.isSysAdmin
        let isAdmin = false
        const service = AccountService.getInstance()
        const accountInfo = await service.GetAccountData(userData.userAccounts[0].accountId)

        if (typeAccount === 2 || isSysAdmin) this.isActionAdminOrSysAdmin = true

        if (typeAccount === 2) isAdmin = true

        if (accountInfo.isPartner) this.isActionPartner = true
      }
      this.setFolderId(0)
      this.setFolderName('')
    },
    computed: {
      ...mapState('sections', [
        'drawerMenu',
        'vaultSelected'
      ]),
      ...mapState('configuracao', [
        'currentInConfiguration'
      ])
    },
    methods: {
      ...mapActions('sections', [
        'setDrawerMenu',
        'setHomePage',
        'setCurrentStatus',
        'setTreeSelected',
        'setFolderId',
        'setFolderName',
        'fetchEnvelops',
        'fetchEnvelopesByVault',
        'setVaultSelected'
      ]),
      ...mapActions('configuracao', [
        'setCurrentInConfiguration'
      ]),
      newDocument () {
        this.$router.push({
          name: 'novoDocumento'
        })
      },
      handleChangeCofre (cofreId: any, infoCofre: any) {
        this.setDrawerMenu(false)
        this.setHomePage(false)
        this.setCurrentStatus(null)
        this.setTreeSelected(['0'])
        this.setFolderId(parseInt(cofreId))
        this.setFolderName(infoCofre.name)
        this.fetchEnvelopesByVault({ folderId: cofreId, page: 1, size: null })
        this.setVaultSelected(true)
      },
      handleChangeConfiguration (option: any) {
        this.setDrawerMenu(false)
        this.setHomePage(false)
        this.setCurrentInConfiguration(option)
      }
    }
  })
