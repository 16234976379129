
  import { defineComponent, reactive, ref, UnwrapRef } from 'vue'
  import { AccountService } from '@/services/AccountService'
  import { TokenService } from '@/services/TokenService'
  import { mapState, mapActions } from 'vuex'
  import { notification } from 'ant-design-vue'
  import { CheckCircleFilled } from '@ant-design/icons-vue'
  import Footer from '@/assets/components/Footer.vue'

  interface FormActiveAccount {
    password: string | null
    rePassword: string | null
  }

  export default defineComponent({
    name: 'ActiveAccount',
    components: {
      CheckCircleFilled,
      Footer
    },
    data: () => ({
      key: '' as string | null | undefined,
      formAccount: reactive<FormActiveAccount>({
        password: null,
        rePassword: null
      }) as UnwrapRef<FormActiveAccount>,
      rules: {
        password: [
          { required: true, message: 'Por favor insira o e-mail', trigger: 'blur' }
        ]
      },
      isMobile: ref<boolean>(false),
      isSuccessActiveAccount: ref<boolean>(false),
      accountService: null as AccountService | null,
      visible: ref<boolean>(false),
      textVisible: ref<number>(0),
      formRef: ref(),
      version: ref<any>(process.env.VUE_APP_VERSION)
    }),

    async mounted () {
      this.accountService = AccountService.getInstance()
      if (window.innerWidth < 768) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }

      this.key = this.$route.query.key?.toString()
      if (this.key) {
        TokenService.setToken(this.key)
      }
    },

    computed: {
      ...mapState('authenticator', ['showModalState'])
    },

    methods: {
      ...mapActions('authenticator', [
        'setEmailUsuario',
        'setAdmin',
        'setLogo',
        'setUserId',
        'setAccountId'
      ]),
      goToLogin () {
        this.isSuccessActiveAccount = false
        this.$router.replace('/login')
      },
      showModal (number: number) {
        this.textVisible = number
        this.visible = true
      },
      handleOk (e: MouseEvent) {
        this.visible = false
      },
      async activeAccount () {
        if (this.formAccount.password === this.formAccount.rePassword) {
            try {
                const res = await this.accountService?.activeAccountPartnerClient(this.formAccount.password)

                this.isSuccessActiveAccount = true
                TokenService.clear()

                // redirect to gateway
                const dataForGatewayPlanPartner = {
                    reference: 1,
                    id: res.id,
                    name: res.name,
                    cpfCnpj: '',
                    domain: '',
                    phone: '',
                    address: '',
                    addressNumber: '',
                    zipCode: '',
                    city: '',
                    state: '',
                    district: '',
                    contact: '',
                    planId: res.planId,
                    productToken: process.env.VUE_APP_ROOT_PRODUCT_TOKEN,
                    productUrl: process.env.VUE_APP_ROOT_PRODUCT_URL,
                    isPartner: true,
                    partnerId: res.partnerId
                }

                setTimeout(() => {
                    const dataForGatewayPlanPartnerEncode = btoa(JSON.stringify(dataForGatewayPlanPartner))
                    window.location.href = process.env.VUE_APP_ROOT_GATEWAY_URL + '/plans?data=' + dataForGatewayPlanPartnerEncode
                }, 5000)
            } catch (err) {
                notification.error({
                    message: 'Falha na ativação da conta',
                    description: 'Não foi possivel ativar sua conta!'
                })
            }
        } else {
            notification.error({
                message: 'Falha na ativação da conta',
                description: 'Os dados dos campos de senha não conferem!'
            })
        }
      }
    }
  })
