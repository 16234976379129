export class CountrieService {
  private static classInstance?: CountrieService

  // https://www.itmnetworks.com.br/ddi-dos-paises-codigos-internacionais-de-telefone
  // https://github.com/luannsr12/ddi-json-flag
  // https://emojipedia.org/pt/bandeira-venezuela/
  // https://github.com/lipis/flag-icons (uso atual)

  private countries: any = []

  public constructor () {
    this.countries = [
      {
        ddi: '+54',
        name: 'Argentina',
        mask: '(#) ### ###-####',
        sigla: 'AR',
        flag: 'fi fi-ar'
      },
      {
        ddi: '+591',
        name: 'Bolívia',
        mask: '(#) ###-####',
        sigla: 'BO',
        flag: 'fi fi-bo'
      },
      {
        ddi: '+55',
        name: 'Brasil',
        mask: ['(##) # ####-####', '(##) ####-####'],
        sigla: 'BR',
        flag: 'fi fi-br'
      },
      {
        ddi: '+56',
        name: 'Chile',
        mask: '(#) ####-####',
        sigla: 'CL',
        flag: 'fi fi-cl'
      },
      {
        ddi: '+57',
        name: 'Colômbia',
        mask: '(#) ###-###-####',
        sigla: 'CO',
        flag: 'fi fi-co'
      },
      {
        ddi: '+593',
        name: 'Equador',
        mask: '(#) ##-###-####',
        sigla: 'EC',
        flag: 'fi fi-ec'
      },
      {
        ddi: '+34',
        name: 'Espanha',
        mask: '(###) ###-###',
        sigla: 'ES',
        flag: 'fi fi-es'
      },
      {
        ddi: '+33',
        name: 'França',
        mask: '(###) ###-####',
        sigla: 'FR',
        flag: 'fi fi-fr'
      },
      {
        ddi: '+592',
        name: 'Guiana',
        mask: '(###) ####',
        sigla: 'GY',
        flag: 'fi fi-gy'
      },
      {
        ddi: '+594',
        name: 'Guiana Francesa',
        mask: '(###) ####',
        sigla: 'GF',
        flag: 'fi fi-gf'
      },
      {
        ddi: '+31',
        name: 'Holanda',
        mask: '(###) ###-####',
        sigla: 'NL',
        flag: 'fi fi-nl'
      },
      {
        ddi: '+500',
        name: 'Ilhas Malvinas',
        mask: '######',
        sigla: 'FK',
        flag: 'fi fi-fk'
      },
      {
        ddi: '+39',
        name: 'Itália',
        mask: '(####) ###-###',
        sigla: 'IT',
        flag: 'fi fi-it'
      },
      {
        ddi: '+595',
        name: 'Paraguai',
        mask: '(###) ######',
        sigla: 'PY',
        flag: 'fi fi-py'
      },
      {
        ddi: '+51',
        name: 'Peru',
        mask: '(#) ###-#####',
        sigla: 'PE',
        flag: 'fi fi-pe'
      },
      {
        ddi: '+351',
        name: 'Portugal',
        mask: '(###) ###-###',
        sigla: 'PT',
        flag: 'fi fi-pt'
      },
      {
        ddi: '+597',
        name: 'Suriname',
        mask: '(###) #####',
        sigla: 'SR',
        flag: 'fi fi-sr'
      },
      {
        ddi: '+598',
        name: 'Uruguai',
        mask: '(#) ###-####',
        sigla: 'UY',
        flag: 'fi fi-uy'
      },
      {
        ddi: '+58',
        name: 'Venezuela',
        mask: '(###) ###-####',
        sigla: 'VE',
        flag: 'fi fi-ve'
      },
      {
        ddi: '+1',
        name: 'Estados Unidos',
        mask: '(###) ###-####',
        sigla: 'US',
        flag: 'fi fi-us'
      }
    ]
  }

  public static getInstance () {
    if (!this.classInstance) {
      this.classInstance = new CountrieService()
    }
    return this.classInstance
  }

  public getCountries () {
    return this.countries
  }
}
