
  import { defineComponent, ref } from 'vue'
  import LogoCertificata from '@/assets/components/LogoCertificata.vue'
  import LogoIconCertificata from '@/assets/components/LogoIconCertificata.vue'

  export default defineComponent({
    name: 'SidebarLogo',
    props: {
      drawer: Boolean
    },
    components: {
      LogoIconCertificata,
      LogoCertificata
    }
  })
