
  import { defineComponent, reactive, ref, UnwrapRef } from 'vue'
  import { LoginService } from '@/services/LoginService'
  import { AccountService } from '@/services/AccountService'
  import { UserService } from '@/services/UserService'
  import { LoginCommandRequest } from '@/services/requests/LoginCommandRequest'
  import { CreateAccountMvoCommandRequest } from '@/services/requests/CreateAccountMvoCommandRequest'
  import { TokenService } from '@/services/TokenService'
  import { mapState, mapActions } from 'vuex'
  import {
    CheckCircleFilled
  } from '@ant-design/icons-vue'
  import { notification } from 'ant-design-vue'

  import ModalTerms from '../modal/modalTerms.vue'
  import ModalPolicy from '../modal/modalPolicy.vue'
  import Footer from '@/assets/components/Footer.vue'

  enum ETypeAccount {
    DEFAULT = 0,
    INDIVIDUAL = 1,
    COMPANY = 2
  }

  interface FormLogin {
    email: string | null
    password: string | null
    checked: boolean
  }

  interface FormAccount {
    name: string | null
    cpf: string | null
    email: string | null
    password: string | null
  }

  export default defineComponent({
    name: 'CreateAccountMvo',
    components: {
      ModalTerms,
      ModalPolicy,
      CheckCircleFilled,
      Footer
    },
    data: () => ({
      key: '' as string | null | undefined,
      isFilledTerms: ref<boolean>(false),
      formLogin: reactive<FormLogin>({
        email: null,
        password: null,
        checked: false
      }) as UnwrapRef<FormLogin>,

      formAccount: reactive<FormAccount>({
        name: null as string | null,
        cpf: null as string | null,
        email: null as string | null,
        password: null as string | null
      }) as UnwrapRef<FormAccount>,
      confirmEmail: null as string | null,
      rules: {
        email: [
          { required: true, message: 'Por favor insira o e-mail', trigger: 'blur' }
        ]
      },
      isMobile: ref<boolean>(false),
      isCreateAccount: ref<boolean>(false),
      isSuccessCreateAccount: ref<boolean>(false),
      isSuccessVerifyUser: ref<boolean>(false),
      isCampaignMvo: ref<boolean>(false),
      campaignMvoCode: ref(''),
      typeAccount: ETypeAccount.DEFAULT as ETypeAccount,
      typeAccountPerson: ref<boolean>(false),
      typeAccountCompany: ref<boolean>(false),
      geolocation: '' as string | null,
      loginService: null as LoginService | null,
      accountService: null as AccountService | null,
      userService: null as UserService | null,
      visible: ref<boolean>(false),
      textVisible: ref<number>(0),
      formRef: ref(),
      partner: '' as string | null | undefined,
      partnerHash: '' as string | null | undefined,
      clientHash: '' as string | null | undefined,
      isPartner: ref<boolean>(false),
      version: ref<any>(process.env.VUE_APP_VERSION)
    }),

    async mounted () {
      if (window.innerWidth < 768) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      this.isCreateAccount = true
      this.isSuccessCreateAccount = false
      this.isSuccessVerifyUser = false
      this.isCampaignMvo = false
      this.key = null

      const token = TokenService.getToken()
      if (token !== '') this.$router.replace('/home')

      // Validate account
      this.userService = UserService.getInstance()
      this.key = this.$route.query.key?.toString()
      if (this.key) {
        TokenService.setToken(this.key!)
        await this.verifyUser()
      }

      // Create account campaign
      this.isCampaignMvo = (this.$route.params.campaign?.toString() !== null && this.$route.params.campaign !== undefined)
      if (this.isCampaignMvo) {
        this.isCreateAccount = true
        this.campaignMvoCode = this.$route.params.campaign?.toString()
        if (this.campaignMvoCode !== 'ymclekq') this.$router.replace('/')
      }
      this.formAccount.name = this.$route.query.nome?.toString() || ''
      this.formAccount.email = this.$route.query.email?.toString() || ''
      this.accountService = AccountService.getInstance()
      this.loginService = LoginService.getInstance()
      this.loginService.getLocation(this.setPosition)
    },

    computed: {
      ...mapState('authenticator', ['showModalState'])
    },

    methods: {
      ...mapActions('authenticator', [
        'setEmailUsuario',
        'setAdmin',
        'setLogo',
        'setUserId',
        'setAccountId'
      ]),

      setPosition (position: any) {
        if (position) {
          this.geolocation = `${position.coords.latitude},${position.coords.longitude}`
        } else this.geolocation = ''
      },

      checkIsFilled () {
        let check = true
        Object.values(this.formAccount).forEach((item) => {
          if (!item) {
            check = false
          }
        })
        return check
      },

      AcceptTerms () {
        this.isFilledTerms = !this.isFilledTerms
      },

      setCreateAccount () {
        this.isCreateAccount = true
      },

      setLoginAccount () {
        this.isCreateAccount = false
        this.isSuccessCreateAccount = false
        this.isSuccessVerifyUser = false
        this.$router.replace('/login')
      },

      showModal (number: number) {
        this.textVisible = number
        this.visible = true
      },

      handleOk (e: MouseEvent) {
        this.visible = false
      },

      async createAccount () {
        const isFilled = this.checkIsFilled()
        if (isFilled) {
          if (this.isFilledTerms) {
            try {
              const request = {
                name: this.formAccount.name,
                email: this.formAccount.email?.trim().toLowerCase(),
                cpf: this.formAccount.cpf,
                password: this.formAccount.password,
                type: this.typeAccount.toString(),
                term: '',
                geolocalization: '',
                partner: null,
                partnerHash: null,
                partnerClientHash: null,
                isPartner: false
              } as CreateAccountMvoCommandRequest

              const res = await this.accountService?.createAccountMvo(request)
              if (res) {
                this.isSuccessCreateAccount = true
              } else {
                notification.error({
                  message: 'Falha no Cadastro',
                  description: 'Não foi possivel criar sua conta!'
                })
              }
            } catch (err) {
              console.error(err)
            }
          } else {
            notification.error({
              message: 'Falha no Cadastro',
              description: 'Você precisa concordar com os Termos de Uso e Política de Privacidade!'
            })
          }
        } else {
          notification.error({
            message: 'Falha no Cadastro',
            description: 'Os Campos: Nome, E-mail e Senha são obrigatórios e precisam ser preenchidos!'
          })
        }
      },

      async verifyUser () {
        try {
          await this.userService?.verify()
          this.isSuccessVerifyUser = true
        } catch (e) {
          this.isSuccessVerifyUser = false
        }
        TokenService.clear()
      },

      checkTypePerson () {
        this.typeAccountCompany = false
        this.typeAccount = (this.typeAccountPerson) ? 2 : 0
      },

      checkTypeCompany () {
        this.typeAccountPerson = false
        this.typeAccount = (this.typeAccountCompany) ? 1 : 0
      }
    }
  })
