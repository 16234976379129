import axios, { AxiosInstance } from 'axios'
import { CepResponse } from './responses/CepResponse'

export class CepService {
    private static classInstance?: CepService;
    protected instance: AxiosInstance;

    public constructor () {
      this.instance = axios.create()
    }

    public static getInstance () {
      if (!this.classInstance) {
        this.classInstance = new CepService()
      }
      return this.classInstance
    }

    public async searchAddress (cep:string):Promise<CepResponse> {
      const response = await this.instance.get<CepResponse>(`https://viacep.com.br/ws/${cep}/json/`)
      return response.data
    }
}
