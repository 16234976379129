import Vuex from 'vuex'

import authenticator from '@/views/login/authenticator/store/index'
import sections from '@/views/home/store/index'
import configuracao from '@/views/configuracao/store/index'
import schedules from '@/views/schedule/store/index'

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    authenticator,
    // cadastros,
    schedules,
    sections,
    configuracao
  }
})
