
import { defineComponent, reactive, ref, UnwrapRef } from 'vue'
import { mapState, mapActions } from 'vuex'
import { CreateAccountPartnerClientCommandRequest } from '@/services/requests/CreateAccountPartnerClientCommandRequest'
import { AccountService } from '@/services/AccountService'
import { PartnerService } from '@/services/PartnerService'
import { IntegrationService } from '@/services/IntegrationService'
import CustomersDataTable from '@/views/configuracao/dataTable/index.vue'
import { ValidateService } from '@/services/ValidateService'

import {
    ShopOutlined,
    UserOutlined,
    CopyOutlined,
    InfoCircleTwoTone,
    ApiOutlined,
    ReadOutlined,
    InfoCircleOutlined
} from '@ant-design/icons-vue'

import { notification } from 'ant-design-vue'

interface FormPartnerClient {
  company: string | null,
  cnpj: string | null,
  name: string | null,
  email: string | null,
  phone: string | null,
  cpf: string | null
}

export default defineComponent({
  name: 'PerfilConfiguration',
  components: {
    ShopOutlined,
    UserOutlined,
    CopyOutlined,
    InfoCircleTwoTone,
    ApiOutlined,
    ReadOutlined,
    InfoCircleOutlined,
    CustomersDataTable
  },
  data: () => ({
    accountService: ref<AccountService>(),
    partnerService: ref<PartnerService>(),
    open: ref<boolean>(false),
    activeKey: ref('1'),
    dataSearch: '',
    linkPartnerClient: ref(''),
    formPartnerClient: reactive<FormPartnerClient>({
      company: null as string | null,
      cnpj: null as string | null,
      name: '' as string,
      email: '' as string,
      cpf: '' as string,
      phone: null as string | null
    }) as UnwrapRef<FormPartnerClient>,
    partner: null,
    partnerName: '',
    partnerHash: ref(''),
    clientHash: null,
    isPartner: false,
    isFeatureApi: false,
    isShowKeysApi: false,
    accountIdApi: ref(''),
    apiKey: ref(''),
    secretKey: ref('')
  }),
  async created () {
    this.accountService = AccountService.getInstance()
    this.partnerService = PartnerService.getInstance()

    const res = await this.partnerService.getPartnerData(this.accountInfo.id)

    this.partner = (res !== null && res.partnerId !== null) ? res : null
    this.partnerName = (res !== null && res.partnerId !== null) ? res.partnerName : ''
    this.partnerHash = (res !== null && res.partnerId !== null) ? res.partnerId : ''
    this.linkPartnerClient = (res !== null && res.partnerId !== null) ? res.partnerLink : ''
    this.isFeatureApi = (res !== null && res.isFeatureApi !== null) ? res.isFeatureApi : false
    this.clientHash = null
    this.isPartner = true
  },
  // watch: {},
  computed: {
    ...mapState('configuracao', ['accountInfo']),
    canCreate () {
      let check = false
      for (const [key, value] of Object.entries(this.formPartnerClient)) {
        if (this.activeKey === '1') {
          if (!value) check = true
        } else {
          if ((key !== 'company' && key !== 'cnpj') && !value) check = true
        }
      }
      return check
    }
  },
  methods: {
    ...mapActions('configuracao', ['setPartnerCustomers']),
    async onSearch () {
        if (this.dataSearch === '') {
            this.cleanSearch()
        } else {
          const partnerHash = (this.partnerHash !== undefined) ? this.partnerHash : null
          const customers = (await this.partnerService?.searchPartnerCustomers(partnerHash, 1, 9, this.dataSearch)) as any
          if (customers !== null && customers.length > 0) {
            this.mountPartnerCustomers(customers)
          } else {
            this.setPartnerCustomers([])
            notification.error({
              message: 'Desculpe!',
              description: 'Não foi encontrado nenhum cliente com esse termo. Utilize o botão de limpeza da pesquisa para voltar à listagem!'
            })
          }
        }
    },
    async cleanSearch () {
      this.dataSearch = ''
      const partnerHash = (this.partnerHash !== undefined) ? this.partnerHash : null
      const customers = (await this.partnerService?.searchPartnerCustomers(partnerHash, 1, 9, this.dataSearch)) as any
      this.mountPartnerCustomers(customers)
    },
    async mountPartnerCustomers (customers: any) {
      const partnerCustomers = [] as any
      customers.forEach((element: any) => {
        let status = ''
        switch (element.status) {
          case 1:
            status = 'Ativo'
            break
          case 2:
            status = 'Vencido'
            break
          case 3:
            status = 'Pendente'
            break
        }

        const it = {
          name: element.customerName,
          expirationDate: element.expiredAt === null ? '--/--/----' : element.expiredAt,
          status: status,
          plan: element.plan,
          accountType: element.accountType,
          id: element.customersAccountId
        }
        partnerCustomers.push(it)
      })

      this.setPartnerCustomers(partnerCustomers)
    },
    showModalCreate () {
      this.open = true
      this.activeKey = '1'
    },
    validateCPF (cpf: string | any, isShowMessage: boolean) {
      let isValidCpf = false
      if (cpf.length === 14) {
        isValidCpf = ValidateService.isValidCpf(cpf)
        if (!isValidCpf) {
          if (isShowMessage) {
            notification.warning({
              message: 'Cpf inválido',
              description: 'O CPF inserido é inválido, favor corrigir.'
            })
          }
          return false
        } else {
            return true
        }
      } else if (cpf.length > 14) {
        if (isShowMessage) {
          notification.warning({
            message: 'Cpf inválido',
            description: 'O CPF inserido possui caracteres há mais, favor corrigir.'
          })
        }
        return false
      } else if (cpf.length < 14) {
        notification.warning({
          message: 'Cpf inválido',
          description: 'O CPF inserido possui caracteres há menos, favor corrigir.'
        })
        return false
      }
    },
    async handleOk () {
      try {
        const isCpfValid = this.validateCPF(this.formPartnerClient.cpf, true)

        if (isCpfValid) {
          const request: CreateAccountPartnerClientCommandRequest = {
            name: this.formPartnerClient.company,
            cpfCnpj: this.formPartnerClient.cnpj,
            phone: this.formPartnerClient.phone,
            email: this.formPartnerClient.email,
            cpfUser: this.formPartnerClient.cpf,
            userName: this.formPartnerClient.name,
            birthDate: null,
            password: '',
            type: parseInt(this.activeKey),
            term: '',
            geolocalization: '',
            partnerHash: (this.partnerHash !== undefined) ? this.partnerHash : null
          } as CreateAccountPartnerClientCommandRequest

          const res = await this.accountService?.createAccountPartnerClient(request)
          if (res) {
            const msg = 'Um link de verificação de conta foi enviado para o e-mail: ' + this.formPartnerClient.email
            notification.success({
              message: 'Conta criada com sucesso!',
              description: msg,
              duration: 6
            })
            // reload clients
          } else {
            notification.error({
              message: 'Erro ao cadastrar!',
              description: 'Não foi possível cadastrar esse cliente.'
            })
          }
          this.open = false
          this.clearDataClient()
        }
      } catch (error) {
        console.log('error: ', error)
      }
    },
    handleCancel () {
      this.open = false
      this.activeKey = '1'
      this.clearDataClient()
    },
    clearDataClient () {
      this.formPartnerClient = {
        company: null,
        cnpj: null,
        name: null,
        email: null,
        phone: null,
        cpf: null
      }
    },
    async copyLinkPartner () {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(this.linkPartnerClient)
            notification.success({
                message: 'O link foi copiado com sucesso!'
            })
        } else {
            const textarea = document.createElement('textarea')
            textarea.value = this.linkPartnerClient
            textarea.style.position = 'absolute'
            textarea.style.left = '-99999999px'
            document.body.prepend(textarea)
            textarea.select()
            try {
                document.execCommand('copy')
            } catch (err) {
                console.log(err)
            } finally {
                textarea.remove()
                notification.success({
                    message: 'O link foi copiado com sucesso!'
                })
            }
        }
    },
    async showModalKeysPartner (partnerHash: any) {
      const response = await IntegrationService.getInstance().getIntegrationPartnerData(partnerHash)
      this.isShowKeysApi = true
      this.accountIdApi = response.id
      this.apiKey = response.apiKey
      this.secretKey = response.secretKey
    },
    async copyAccoundId () {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(this.accountIdApi)
            notification.success({
                message: 'O Hash foi copiado com sucesso!'
            })
        } else {
            const textarea = document.createElement('textarea')
            textarea.value = this.accountIdApi
            textarea.style.position = 'absolute'
            textarea.style.left = '-99999999px'
            document.body.prepend(textarea)
            textarea.select()
            try {
                document.execCommand('copy')
            } catch (err) {
                console.log(err)
            } finally {
                textarea.remove()
                notification.success({
                    message: 'O Hash foi copiado com sucesso!'
                })
            }
        }
    },
    async copyApiKey () {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(this.apiKey)
            notification.success({
                message: 'A chave de API foi copiada com sucesso!'
            })
        } else {
            const textarea = document.createElement('textarea')
            textarea.value = this.apiKey
            textarea.style.position = 'absolute'
            textarea.style.left = '-99999999px'
            document.body.prepend(textarea)
            textarea.select()
            try {
                document.execCommand('copy')
            } catch (err) {
                console.log(err)
            } finally {
                textarea.remove()
                notification.success({
                    message: 'A chave de API foi copiada com sucesso!'
                })
            }
        }
    },
    async copySecretKey () {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(this.secretKey)
            notification.success({
                message: 'A chave secreta foi copiada com sucesso!'
            })
        } else {
            const textarea = document.createElement('textarea')
            textarea.value = this.secretKey
            textarea.style.position = 'absolute'
            textarea.style.left = '-99999999px'
            document.body.prepend(textarea)
            textarea.select()
            try {
                document.execCommand('copy')
            } catch (err) {
                console.log(err)
            } finally {
                textarea.remove()
                notification.success({
                    message: 'A chave secreta foi copiada com sucesso!'
                })
            }
        }
    }
  }
})
