import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6591830a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ic-perfil" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BankOutlined = _resolveComponent("BankOutlined")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.accountType === 1)
      ? (_openBlock(), _createBlock(_component_BankOutlined, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.accountType === 2)
      ? (_openBlock(), _createBlock(_component_UserOutlined, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}