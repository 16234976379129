export default () => ({
  openSidebar: true,
  envelopes: null,
  envelopesOthersPendingSign: null,
  envelopesINeedToSign: null,
  folderId: localStorage.getItem('folderId'),
  historicEnvelopes: null,
  openHistoric: false,
  showSpinContent: false,
  openNewDocument: false,
  folderName: localStorage.getItem('folderName'),
  treeSelected: [],
  isHome: true,
  currentStatus: 0,
  drawerMenu: false,
  vaultSelected: false
})
