import { ActionTree } from 'vuex'

import types from './types'
const { GENERIC_MUTATION } = types

const setShowModal = ({ commit }: any, value: boolean) =>
  commit(GENERIC_MUTATION, {
    property: 'showModalState',
    value: value
  })

const setEmailUsuario = ({ commit }: any, value: string) =>
  commit(GENERIC_MUTATION, {
    property: 'email',
    value
  })

const setAdmin = ({ commit }: any, value: boolean) =>
  commit(GENERIC_MUTATION, {
    property: 'isAdmin',
    value
  })

const setAccountId = ({ commit }: any, value: boolean) =>
  commit(GENERIC_MUTATION, {
    property: 'accountId',
    value
  })

const setUserId = ({ commit }: any, value: boolean) =>
  commit(GENERIC_MUTATION, {
    property: 'userId',
    value
  })

const setLogo = ({ commit }: any, value: string) =>
  commit(GENERIC_MUTATION, {
    property: 'logo',
    value
  })

export default <ActionTree<any, any>>{
  setShowModal,
  setEmailUsuario,
  setLogo,
  setAdmin,
  setAccountId,
  setUserId
}
