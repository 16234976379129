
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import { UserService } from '@/services/UserService'
import { AccountService } from '@/services/AccountService'
import { notification } from 'ant-design-vue'
import { TokenService } from '@/services/TokenService'
import moment from 'moment'

export default defineComponent({
  name: 'PerfilConfiguration',
  data () {
    return {
      formValues: {
        name: '' as string,
        email: '' as string,
        oldPassword: '' as string,
        password: '' as string,
        confirmPassword: '' as string,
        cpf: '' as string,
        birthDate: null as any,
        telefone: '' as string
      },
      newSenha: false,
      service: null as any,
      birthDate: null as any,
      serviceAccount: null as any
    }
  },
  created () {
    this.formValues = cloneDeep(this.userInfo)
    this.birthDate = this.formatDate(this.formValues.birthDate)
    this.service = UserService.getInstance()
    this.serviceAccount = AccountService.getInstance()
  },
  watch: {
    userInfo () {
      this.formValues = cloneDeep(this.userInfo)
      this.birthDate = this.formatDate(this.formValues.birthDate)
    }
  },
  computed: {
    ...mapState('configuracao', ['userInfo']),
    canSave () {
      if (
        this.formValues.oldPassword != null &&
        this.formValues.password !== this.formValues.confirmPassword
      ) {
        return false
      }

      return true
    }
  },
  methods: {
    ...mapActions('configuracao', ['setUserInfo']),
    async checkSenha () {
      if (this.newSenha) {
        this.newSenha = false
      } else {
        this.newSenha = true
      }
    },
    async save () {
      if (
        this.formValues.name === '' ||
        this.formValues.email === '' ||
        this.formValues.oldPassword === '' ||
        this.birthDate === '' ||
        this.birthDate === null
      ) {
        notification.error({
          message: 'Erro ao salvar usuário',
          description: 'Existem informações em branco, favor corrigir.'
        })
      } else {
        if (this.birthDate.length !== 10) {
          notification.error({
            message: 'Erro ao salvar usuário',
            description: 'Formato de data inválido, favor corrigir.'
          })
        } else {
          try {
            this.formValues.birthDate = moment(this.birthDate, 'DD/MM/YYYY').toISOString()
            const result = await this.service.updateUserData(this.formValues)

            TokenService.setToken(result.token)
            const userDataByToken = TokenService.getUserData()

            const userInfo = await this.serviceAccount.GetUserData(userDataByToken.userId)
            this.setUserInfo(userInfo)

            this.formValues = cloneDeep(this.userInfo)

            this.newSenha = false
            notification.success({
              message: 'Usuário alterado',
              description: 'As informações do usuário foram alteradas com sucesso'
            })
          } catch (err) {
            console.error(err)
          }
        }
      }
    },
    formatDate (date: any) {
      return moment(date).format('DD/MM/YYYY')
    }
  }
})
