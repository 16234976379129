import { set } from 'lodash'
import types from './types'

const { GENERIC_MUTATION } = types

export default {
    [GENERIC_MUTATION] (state: any, { property, value }: any) {
      set(state, property, value)
    }
}
