import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createBlock(_component_a_image, {
    id: "image",
    src: $options.getLogo(),
    preview: false,
    style: {"width":"25px","margin-top":"10px"}
  }, null, 8, ["src"]))
}