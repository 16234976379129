
  import { defineComponent, ref } from 'vue'
  import moment from 'moment'

  export default defineComponent({
    name: 'FooterPages',
    data: () => ({
      version: ref<any>(process.env.VUE_APP_VERSION),
      currentYear: ref<any>(moment().format('YYYY'))
    })
  })

