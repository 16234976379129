
import { TokenService } from '@/services/TokenService'
import { AccountService } from '@/services/AccountService'
import Perfil from '@/views/configuracao/content/perfil.vue'
import Account from '@/views/configuracao/content/account.vue'
import Users from '@/views/configuracao/content/users.vue'
import Vaults from '@/views/configuracao/content/vaults.vue'
import Customers from '@/views/configuracao/content/customers.vue'
import Header from '@/assets/components/Header.vue'
import Sidebar from '@/assets/components/Sidebar.vue'
import Footer from '@/assets/components/Footer.vue'

import {
  DownOutlined,
  LogoutOutlined,
  HomeOutlined
} from '@ant-design/icons-vue'
import { defineComponent, ref } from 'vue'
import { mapState, mapActions } from 'vuex'

export default defineComponent({
  name: 'TelaConfiguracao',
  components: {
    Header,
    Sidebar,
    Footer,
    Perfil,
    Account,
    Users,
    Vaults,
    Customers
  },
  data: () => ({
    // selecionado: 0 as number,
    // itemObj: {
    //   collapsed: ref<boolean>(false)
    // }
  }),

  async created () {
    try {
      const service = AccountService.getInstance()

      const accountInfo = await service.GetAccountData(this.accountId)
      const userInfo = await service.GetUserData(this.userId)

      this.setAccountInfo(accountInfo)
      this.setUserInfo(userInfo)
    } catch (err) { }
  },

  computed: {
    ...mapState('authenticator', [
      'email',
      'logo',
      'isAdmin',
      'accountId',
      'userId'
    ]),
    ...mapState('sections', [
        'drawerMenu'
    ]),
    ...mapState('configuracao', [
        'currentInConfiguration'
    ])
  },
  methods: {
    ...mapActions('sections', ['fetchEnvelops', 'setOpenNewDocument', 'setDrawerMenu']),
    ...mapActions('configuracao', ['setAccountInfo', 'setUserInfo', 'setCurrentInConfiguration']),
    getEmail (): string {
      const userData = TokenService.getUserData()
      return userData.userEmail
    },
    getLogo (): string {
      return require('@/images/logo.png')
    },
    newDocument () {
      this.$router.push({
        name: 'novoDocumento'
      })
    },
    logout () {
      TokenService.clear()
      this.$router.push({
        name: 'authenticator'
      })
    }
  }
})
