import { ActionTree } from 'vuex'
import { ScheduleService } from '@/services/ScheduleService'

import types from './types'
const { GENERIC_MUTATION } = types

const setSchedules = ({ commit }: any, schedule: any) => {
  commit(GENERIC_MUTATION, {
    property: 'schedules',
    value: schedule
  })
}

const fetchSchedules = async (
  { dispatch }: any
  ) => {
    try {
      const schedules = (await ScheduleService.getInstance().getSchedules()) as any
      dispatch('setSchedules', schedules)
    } catch (err) { }
}

const fetchScheduleFavorites = async (
  { dispatch }: any,
  { userId }: any
  ) => {
    try {
        const schedules = (await ScheduleService.getInstance().getScheduleFavorites(userId)) as any
        dispatch('setSchedules', schedules)
    } catch (err) { }
}

export default <ActionTree<any, any>>{
    setSchedules,
    fetchSchedules,
    fetchScheduleFavorites
}
