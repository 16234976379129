import { AxiosResponse } from 'axios'
import { HTTPBaseService } from './HTTPBaseService'
import { CreateAccountIndividualCommandRequest } from '@/services/requests/CreateAccountIndividualCommandRequest '
import { CreateAccountCompanyCommandRequest } from '@/services/requests/CreateAccountCompanyCommandRequest '
import { CreateAccountMvoCommandRequest } from '@/services/requests/CreateAccountMvoCommandRequest'
import { CreateAccountTestABCommandRequest } from '@/services/requests/CreateAccountTestABCommandRequest'
import { RecSenhaRequest } from './requests/RecSenhaRequest'
import { RecSenhaResponse } from './responses/RecSenhaResponse'
import { AccountDataResponse } from './responses/AccountDataResponse'
import { UserDataResponse } from './responses/UsersDataResponse'
import { AccountUpdate } from './requests/AccountUpdate'
import { UserUpdate } from './requests/UserUpdate'
import { CreateAccountPartnerClientCommandRequest } from './requests/CreateAccountPartnerClientCommandRequest'

export class AccountService extends HTTPBaseService {
  private static classInstance?: AccountService

  constructor () {
    super(process.env.VUE_APP_ROOT_API_SHAR as string)
  }

  public static getInstance () {
    if (!this.classInstance) {
      this.classInstance = new AccountService()
    }

    return this.classInstance
  }

  public async createIndividual (
    request: CreateAccountIndividualCommandRequest
  ): Promise<any> {
    const { data } = await this.instance.post<CreateAccountIndividualCommandRequest>(
        '/core/api/v1/accounts/individual',
        request
      )

    return data
  }

  public async createCompany (
    request: CreateAccountCompanyCommandRequest
  ): Promise<any> {
    const { data } = await this.instance.post<CreateAccountIndividualCommandRequest>(
        '/core/api/v1/accounts/company',
        request
      )

    return data
  }

  public async createUserIntegration (): Promise<any> {
    const { data } = await this.instance.post<any>(
      '/integration/api/v1/integrations/create/user',
      {
        params: {
          folderId: 0
        }
      }
    )

    return data
  }

  public async createAccountMvo (
    request: CreateAccountMvoCommandRequest
  ): Promise<any> {
    const { data } = await this.instance.post<CreateAccountMvoCommandRequest>(
      '/core/api/v1/accounts/mvo',
      request
    )

    return data
  }

  public async createAccountTestAB (
    request: CreateAccountTestABCommandRequest
  ): Promise<any> {
    const { data } = await this.instance.post<CreateAccountTestABCommandRequest>(
      '/core/api/v1/accounts/testab',
      request
    )

    return data
  }

  public async createAccountPartnerClient (
    request: CreateAccountPartnerClientCommandRequest
  ): Promise<any> {
    const { data } = await this.instance.post<CreateAccountPartnerClientCommandRequest>(
      '/core/api/v1/accounts/partner-client',
      request
    )

    return data
  }

  public async sendEmailRecoveryPassword (
    request: RecSenhaRequest
  ): Promise<RecSenhaResponse | unknown> {
    const response: AxiosResponse<RecSenhaResponse | unknown> =
      await this.instance.post<RecSenhaRequest>(
        '/core/api/v1/users/request-recover-password',
        request
      )

    return response
  }

  public async sendSenhaRecover (
    request: RecSenhaRequest
  ): Promise<RecSenhaResponse | unknown> {
    const response: AxiosResponse<RecSenhaResponse | unknown> =
      await this.instance.put<RecSenhaRequest>(
        '/core/api/v1/users/recover-password',
        request
      )

    return response
  }

  public async accountUpdate (
    request: AccountUpdate
  ): Promise<AccountUpdate | unknown> {
    const response: AxiosResponse<AccountUpdate | unknown> =
      await this.instance.put<AccountUpdate>(
        '/core/api/v1/accounts/data',
        request
      )

    return response
  }

  public async GetAccountData (
    accountId: number | null = null
  ): Promise<AccountDataResponse> {
    const { data } = await this.instance.get<AccountDataResponse>(
      '/core/api/v1/accounts/data',
      { params: { accountId } }
    )

    return data
  }

  public async GetLimitWhatsappAccount (
    accountId: number | null = null
  ): Promise<any> {
    const { data } = await this.instance.get<any>(
      '/core/api/v1/envelopes/limit/whatsapp',
      { params: { accountId } }
    )

    return data
  }

  public async GetLimitEnvelopesAccount (
    accountId: number | null = null
  ): Promise<any> {
    const { data } = await this.instance.get<any>(
      '/core/api/v1/envelopes/limit',
      { params: { accountId } }
    )

    return data
  }

  public async GetUserData (
    userId: number | null = null
  ): Promise<UserDataResponse> {
    const { data } = await this.instance.get<UserDataResponse>(
      '/core/api/v1/users/data',
      { params: { userId } }
    )

    return data
  }

  public async activeAccountPartnerClient (
    password: string | null = null
  ): Promise<any> {
    const { data } = await this.instance.put<any>(
      '/core/api/v1/accounts/active-account-by-customer-partner',
      { password }
    )

    return data
  }
}
