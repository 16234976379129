
import { EnvelopeInfoResponse } from '@/services/responses/EnvelopeInfoResponse'
import { HistoryOutlined } from '@ant-design/icons-vue'
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import { defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'
export default defineComponent({
  name: 'DrawerHistory',
  components: {
    HistoryOutlined
  },
  data: () => ({
    loading: ref<boolean>(false),
    simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
  }),
  computed: {
    ...mapState('sections', [
      'openHistorico',
      'folderId',
      'envelopes',
      'historicoEnvelopes'
    ]),
    history (): EnvelopeInfoResponse[] {
      return this.historicoEnvelopes || []
    }
  },
  methods: {
    ...mapActions('sections', ['setOpenHistorico', 'fetchHistoricoEnvelops']),
    onClose () {
      this.setOpenHistorico(false)
    },
    formatDate (date: Date) {
      return moment(date).format('DD/MM/YYYY')
    }
  }
})
