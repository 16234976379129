
    import { defineComponent, ref, h, createVNode } from 'vue'
    import { mapActions, mapState } from 'vuex'
    import { ListSchedule } from '@/services/responses/ListScheduleResponse'
    import { ScheduleService } from '@/services/ScheduleService'
    import { TokenService } from '@/services/TokenService'
    import {
      MoreOutlined,
      StarOutlined,
      FormOutlined,
      DeleteOutlined,
      StarFilled,
      ExclamationCircleOutlined
    } from '@ant-design/icons-vue'
    import { notification, Modal } from 'ant-design-vue'

    export default defineComponent({
        name: 'ScheduleDatatable',
        components: {
            MoreOutlined,
            StarOutlined,
            FormOutlined,
            DeleteOutlined,
            StarFilled
        },
        props: {
            favorites: {
                type: Boolean
            }
        },

        computed: {
            ...mapState('schedules', ['schedules']),
            listSchedules (): ListSchedule[] {
                return this.schedules !== null ? this.schedules : []
            }
        },

        data: () => ({
            showActions: ref<boolean>(true),
            columns: [
                {
                    title: 'Nome',
                    dataIndex: 'name',
                    key: 'name',
                    resizable: true,
                    width: 250
                },
                {
                    title: 'Canal de coleta',
                    dataIndex: 'collectSignature',
                    key: 'collectSignature',
                    width: 250
                },
                {
                    title: 'Autenticar por',
                    dataIndex: 'documentType',
                    key: 'documentType',
                    width: 150
                },
                {
                    title: 'Documento requerido',
                    dataIndex: 'requiredDocument',
                    key: 'requiredDocument',
                    width: 200
                },
                {
                    title: '',
                    key: 'action',
                    slots: { customRender: 'action' }
                }
            ],
            scheduleService: ref<ScheduleService>(),
            userId: ref<any>(null)
        }),

        async mounted () {
            this.scheduleService = ScheduleService.getInstance()
            this.userId = TokenService.getUserData().userId
        },

        methods: {
            ...mapActions('schedules', ['fetchSchedules', 'fetchScheduleFavorites']),
            async setFavorite (item: any) {
                const res = await this.scheduleService?.favoriteSchedule(this.userId, item.id)
                if (res) {
                    notification.success({
                        message: 'Contato favoritado!',
                        description: 'O contato foi adicionado à sua lista de favoritos com sucesso.'
                    })
                } else {
                    notification.error({
                        message: 'Falha ao favoritar contato!',
                        description: 'Este contato já está em sua lista de favoritos.'
                    })
                }
            },

            async removeFavorite (item: any) {
                await this.scheduleService?.removeFavoriteSchedule(this.userId, item.id)
                this.fetchScheduleFavorites({ userId: this.userId })
                notification.success({
                    message: 'Contato removido dos favoritos!',
                    description: 'O contato foi removido da sua lista de favoritos com sucesso.'
                })
            },

            editSchedule (item: any) {
                this.$emit('createOrEdit', item, this.favorites)
            },

            async deleteSchedule (item: any) {
                const msg = 'Ao remover este contato da lista essa ação não poderá ser revertida.'
                Modal.confirm({
                    title: 'Deseja realmente remover?',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: createVNode('div', { }, msg),
                    okText: 'Sim',
                    cancelText: 'Cancelar',
                    centered: true,
                    onOk: async () => {
                        await this.scheduleService?.removeSchedule(this.userId, item.id)
                        this.reloadDataTable()
                    }
                })
            },

            reloadDataTable () {
                if (this.favorites) {
                    this.fetchScheduleFavorites({ userId: this.userId })
                } else {
                    this.fetchSchedules()
                }
            },

            onSelectTableChange (selectedRowKeys: any, selectedRows: any) {
                if (selectedRowKeys.length > 1) {
                    this.showActions = false
                } else {
                    this.showActions = true
                }
                this.$emit('contactsToEnvelope', selectedRows)
            }
        }
    })
