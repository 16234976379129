import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { HTTPBaseService } from './HTTPBaseService'
import { ListEnvelopeResponse } from '@/services/responses/ListEnvelopeResponse'
import { EnvelopeInfoResponse } from './responses/EnvelopeInfoResponse'
import { EnvelopeInfoByUserResponse } from './responses/EnvelopeInfoByUserResponse'
import { eStatusSigned } from './enums/eStatusSigned'
import * as qs from 'qs'
export class EnvelopeService extends HTTPBaseService {
  private static classInstance?: EnvelopeService;

  constructor () {
    super(process.env.VUE_APP_ROOT_API_SHAR as string)
  }

  public static getInstance () {
    if (!this.classInstance) {
      this.classInstance = new EnvelopeService()
    }

    return this.classInstance
  }

  public async listEnvelopes (
    folderId: number | null,
    pageNumber: number,
    pageSize: number
  ): Promise<ListEnvelopeResponse> {
    const { data } = await this.instance.get<ListEnvelopeResponse>(
      '/core/api/v1/envelopes',
      { params: { folderId, pageNumber, pageSize } }
    )
    return data
  }

  public async listEnvelopesByVault (
    folderId: number | null,
    pageNumber: number,
    pageSize: number
  ): Promise<ListEnvelopeResponse> {
    const { data } = await this.instance.get<ListEnvelopeResponse>(
      '/core/api/v1/envelopes/byvault',
      { params: { folderId, pageNumber, pageSize } }
    )
    return data
  }

  public async listInfoEnvelope (
    folderId: number | null = null,
    envelopeId: number[] | null = null
  ): Promise<EnvelopeInfoResponse[]> {
    let config:AxiosRequestConfig = {
      params: { folderId, envelopeId }
    }

    if (envelopeId) {
      config = {
        ...config,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      }
    }

    const { data } = await this.instance.get<EnvelopeInfoResponse[]>(
      '/core/api/v1/envelopes/infos', config
    )

    return data
  }

  public async GetInfoEnvelope (
    userId: number | null = null
  ): Promise<EnvelopeInfoByUserResponse> {
    const { data } = await this.instance.get<EnvelopeInfoByUserResponse>(
      '/core/api/v1/envelopes/info/signature',
      { params: { userId } }
    )

    return data
  }

  public async searchEnvelope (
    term: string,
    status: string | null = null,
    folderId: number | null = null
  ): Promise<ListEnvelopeResponse> {
    const { data } = await this.instance.get<ListEnvelopeResponse>(
      '/core/api/v1/envelopes/search',
      { params: { term, status, folderId } }
    )

    return data
  }

  public async listEnvelopesByUser (
    pageNumber: number,
    pageSize: number,
    status: eStatusSigned = eStatusSigned.PENDING,
    limit: number | null = null,
    userId: number | null = null
  ): Promise<ListEnvelopeResponse> {
    const { data } = await this.instance.get<ListEnvelopeResponse>(
      '/core/api/v1/envelopes/byUser',
      { params: { userId, pageNumber, pageSize, status, limit } }
    )
    return data
  }

  public async cancelEnvelope (
    id:number
  ): Promise<void> {
    await this.instance.put(`/core/api/v1/envelopes/cancel?envelopeId=${id}`)
  }

  public async removeEnvelope (
    id:number
  ): Promise<void> {
    await this.instance.put(`/core/api/v1/envelopes/remove?envelopeId=${id}`)
  }

  public async createEnvelope (formData: FormData) {
    const response = await this.instance.postForm(
      '/core/api/v1/envelopes',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  public async detailsEnvelope (
    hashId: string
  ): Promise<any> {
    const { data } = await this.instance.get<any>(
      `/core/api/v1/envelopes/details/${hashId}`
    )
    return data
  }

  public async changeRecipientEnvelope (
    formData: any
  ): Promise<any> {
    const { data } = await this.instance.post<any>('/core/api/v1/envelopes/recipient/change', formData)
    return data
  }

  public async changeVaultEnvelope (
    envelopeId:number,
    vaultId:number
  ) : Promise<any> {
    await this.instance.put(`/core/api/v1/envelopes/change/vault?envelopeId=${envelopeId}&vaultId=${vaultId}`)
  }
 }
