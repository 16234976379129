import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sidebar-logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoCertificata = _resolveComponent("LogoCertificata")!
  const _component_LogoIconCertificata = _resolveComponent("LogoIconCertificata")!

  return (!_ctx.drawer)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_LogoCertificata)
      ]))
    : (_openBlock(), _createElementBlock("section", {
        key: 1,
        class: _normalizeClass(["sidebar-drawer-logo", {'is-sider-drawer-logo': _ctx.drawer}])
      }, [
        _createVNode(_component_LogoIconCertificata)
      ], 2))
}