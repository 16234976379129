
import { defineComponent } from 'vue'
import Header from '@/assets/components/Header.vue'

export default defineComponent({
  name: 'DocumentPage',
  components: {
    Header
  }
})
