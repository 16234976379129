import moment from 'moment'
export class ValidateService {
    public static titleize (text: string): string {
      const words = text.trim().replace(/\s+/g, ' ').toLowerCase().split(' ')
      const titleizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))

      return titleizedWords.join(' ')
    }

    public static isValidCpf (cpf: string): boolean {
      return ValidateService.checkCpf(cpf.replace(/[^\d]+/g, ''))
    }

    private static checkCpf (strCpf: string): boolean {
      let sum: number
      let rest: number
      sum = 0

      if (strCpf.length !== 11 ||
        strCpf === '00000000000' ||
        strCpf === '11111111111' ||
        strCpf === '22222222222' ||
        strCpf === '33333333333' ||
        strCpf === '44444444444' ||
        strCpf === '55555555555' ||
        strCpf === '66666666666' ||
        strCpf === '77777777777' ||
        strCpf === '88888888888' ||
        strCpf === '99999999999') return false

      for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(strCpf.substring(i - 1, i)) * (11 - i)
      }
      rest = (sum * 10) % 11

      if ((rest === 10) || (rest === 11)) rest = 0
      if (rest !== parseInt(strCpf.substring(9, 10))) return false

      sum = 0

      for (let i = 1; i <= 10; i++) sum = sum + parseInt(strCpf.substring(i - 1, i)) * (12 - i)
      rest = (sum * 10) % 11

      if ((rest === 10) || (rest === 11)) rest = 0
      if (rest !== parseInt(strCpf.substring(10, 11))) return false

      return true
    }
}
