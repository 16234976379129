import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import moment from 'moment'
import { HTTPBaseService } from './HTTPBaseService'
import { UsuariosResponse } from './responses/UsuariosResponse'
import { UsuarioDtoRequest } from './requests/UsuarioDtoRequest'
import { UsuariosDtoResponse } from './responses/UsuariosDtoResponse'
import { TokenService } from './TokenService'
import { RecSenhaRequest } from './requests/RecSenhaRequest'
import { LinkUsuarioDtoRequest } from './requests/LinkUsuarioDtoRequest'
import { LinkUsuarioDtoResponse } from './responses/LinkUsuarioDtoResponse'

export class UserService extends HTTPBaseService {
  private static classInstance?: UserService

  constructor () {
    super(process.env.VUE_APP_ROOT_API_SHAR as string)
  }

  public static getInstance () {
    if (!this.classInstance) {
      this.classInstance = new UserService()
    }
    return this.classInstance
  }

  public async validatePassword (password: string): Promise<void> {
    await this.instance.post<string>(
      '/core/api/v1/users/validate-password',
      null,
      { params: { password } }
    )
  }

  public async createUser (payload: RecSenhaRequest): Promise<void> {
    await this.instance.post<RecSenhaRequest>('/core/api/v1/users/add', payload)
  }

  public async getDataUserById (userId: number | null = null): Promise<any> {
    const { data } = await this.instance.get<any>(
      '/core/api/v2/users/data',
      { params: { userId } }
    )
    return data
  }

  public async getUsers (includeUserLogged = false): Promise<UsuariosResponse> {
    const { data } = await this.instance.get<UsuariosResponse>(
      '/core/api/v1/users/list-by-account',
      { params: { includeUserLogged } }
    )

    return data
  }

  public async getAllUsers (
    accountId: UsuarioDtoRequest)
    : Promise<UsuariosDtoResponse | unknown> {
    const response: AxiosResponse<UsuariosDtoResponse | unknown> =
      await this.instance.get<UsuarioDtoRequest>(
        '/core/api/v2/users/all',
        { params: { accountId } }
      )

    return response.data
  }

  public async getLinkUser (userId: LinkUsuarioDtoRequest) : Promise<LinkUsuarioDtoResponse> {
    const { data } = await this.instance.post<LinkUsuarioDtoResponse>(
      '/core/api/v2/users/link',
      { userId }
    )

    return data
  }

  public async deleteUser (userId: number): Promise<void> {
    await this.instance.delete<number>(`/core/api/v1/users/${userId}`)
  }

  public async changeTypeUser (userId: number): Promise<void> {
    await this.instance.post<any>(`/core/api/v1/users/change-type-user/${userId}`)
  }

  public async updateUserData (payload: any): Promise<any> {
    const { data } =
      await this.instance.put<any>(
        '/core/api/v1/users/data',
        payload
      )

    return data
  }

  public async registerData (payload: any): Promise<boolean> {
    const response =
      await this.instance.put(
        '/core/api/v1/users/register',
        payload
      )

    return (
      response.status !== 400 &&
      response.status !== 401 &&
      response.status !== 403 &&
      response.status !== 500
    )
  }

  public async verify (): Promise<void> {
    const instance = axios.create({
      baseURL: this.baseURL,
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`
      }
    })
    const response = await instance.put('/core/api/v1/users/verify', null, {
      params: {
        ValidateDate: moment().format()
      }
    })
    if (response.status === 401) throw new Error(response.statusText)
  }
}
